import { Component, OnInit, ChangeDetectorRef, ChangeDetectionStrategy, Input } from '@angular/core';
import { AccountDialogComponent } from '../modals/account-dialog/account-dialog.component';
import { AccountService, SubscriptionType } from 'src/app/services/account.service';
import { environment } from 'src/environments/environment'
import { StackedModalsService } from 'src/app/services/stacked-modals.service';
import { SettingsService } from 'src/app/services/settings.service';
import { MapService } from 'src/app/services/map.service';
import { LanguageService } from 'src/app/services/language.service';
import { SubscriptionsDialogComponent } from '../modals/subscriptions-dialog/subscriptions-dialog.component';
import { HistoryDialogComponent } from '../modals/history-dialog/history-dialog.component';
import { ConsoleLoggerService } from 'src/app/services/console-logger.service';
import { User } from 'src/app/models/user';
import { first, fromEvent, merge } from 'rxjs';
import { UtilsService } from 'src/app/services/utils.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProfileComponent implements OnInit {

  @Input() loadingMap: string;
  public isAuthorized: boolean = false;
  public dropdownVisible: boolean = false;
  public username: string = "";
  public apiUrl: string = environment.API_URL;
  public subscriptionType: SubscriptionType;

  constructor(public accountService: AccountService, private cdr: ChangeDetectorRef,
    private stackedModalsService: StackedModalsService, public settingsService: SettingsService,
    private mapService: MapService, public languageService: LanguageService, private consoleLoggerService: ConsoleLoggerService,
    private utilsService: UtilsService) {
  }

  ngOnInit(): void {
    this.accountService.ObservableIsAuthorized.subscribe((resp) => {
      if (resp != null && resp != undefined) {
        this.isAuthorized = resp;
        if (resp == false) {
          this.username = "";
          this.dropdownVisible = false;
        }
        this.cdr.detectChanges();
      }
    });

    this.accountService.ObservableUser.subscribe((resp: User) => {
      if (resp != null && resp != undefined && resp.Profile.FirstName) {

        this.username = resp.Profile.LastName ? resp.Profile.FirstName + " " + resp.Profile.LastName : resp.Profile.FirstName;
        this.cdr.detectChanges();
      }
    });

    this.settingsService.ObservableMap.subscribe((resp) => {
      this.cdr.detectChanges();
    });

    this.languageService.ObservableLanguageLoaded.subscribe((resp) => {
      if (resp) {
        this.cdr.detectChanges();
      }
    });

    this.accountService.ObservableSubscriptionType.subscribe((resp) => {
      if (resp) {
        this.subscriptionType = resp;
        this.cdr.detectChanges();
      }
    });
  }

  public profileClick(): void {
    if (this.isAuthorized) {
      if (this.dropdownVisible !== true) {
        // Open the dropdown
        this.dropdownVisible = true;
        this.cdr.detectChanges();

        const clickEvent = fromEvent(document, 'click', { capture: true });
        const touchstartEvent = fromEvent(document, 'touchstart', { capture: true });

        // Subscribe to events to close the dropdown
        merge(clickEvent, touchstartEvent).pipe(first()).subscribe((event: Event) => {
          if (!this.utilsService.isClassInParentChain(event, "profile-outer")) {
            this.dropdownVisible = false;
            this.cdr.detectChanges();
          }
        });
      } else {
        // Close the dropdown if it’s already open
        this.dropdownVisible = false;
        this.cdr.detectChanges();
      }
    }
    else {
      this.openAccountDialog();
    }
  }

  public openSubscriptionsDialog() {
    this.stackedModalsService.openModal(SubscriptionsDialogComponent, {
      autoFocus: false,
      panelClass: 'dialog-snap-to-fullscreen'
    });
  }

  public openAccountDialog(): void {
    const process = this.accountService.getIsAuthorized() ? "profileEdit" : "signIn";
    this.stackedModalsService.openModal(AccountDialogComponent, {
      data: { process: process },
      autoFocus: false,
      panelClass: 'dialog-snap-to-fullscreen'
    });
  }

  public openHistoryDialog(): void {
    this.stackedModalsService.openModal(HistoryDialogComponent, {
      panelClass: 'dialog-snap-to-fullscreen', autoFocus: false
    });
  }

  public logout(): void {
    this.accountService.logout();
  }
}
