<div id="profile" class="profile-outer" [ngClass]="{'dropdown-visible': dropdownVisible}" (click)="profileClick()">
    <div class="profile-container unauthorized" *ngIf="!isAuthorized">
        <img class="user-icon-default" [src]="'/assets/icons/' + settingsService.getMap().Skin + '/ui/user_icon.svg'" />
        <h6>{{ languageService.languageJSON.Global_Login }}</h6>
    </div>
    <div class="profile-container authorized" *ngIf="isAuthorized">
        <img *ngIf="accountService.getUserAvatar()==null" class="user-icon-default"
            [src]="'/assets/icons/' + settingsService.getMap().Skin + '/ui/user_icon.svg'" />
        <img *ngIf="accountService.getUserAvatar()!=null" class="user-icon"
            [src]="apiUrl + 'storage' + accountService.getUserAvatar()" />
        <h6 class="username" [ngClass]="{'small-padding': subscriptionType?.includes('pro.'), 'medium-padding': subscriptionType?.includes('proplus.') }">{{ username }}</h6>
        <img *ngIf="subscriptionType?.includes('pro.')" id="pro-badge" src="assets/icons/default/ui/PRO_icon.svg"/>
            <img *ngIf="subscriptionType?.includes('proplus.')" id="proplus-badge" src="assets/icons/default/ui/PROPLUS_icon.svg"/>
    </div>
</div>
<div id="profile-dropdown" *ngIf="isAuthorized" [ngClass]="{'dropdown-visible': dropdownVisible}">
    <div class="dropdown-el" (click)="openAccountDialog()">
        <h5 class="dropdown-el-text">{{ languageService.languageJSON.Global_EditAccount }}</h5>
        <img class="dropdown-el-icon" [src]="'/assets/icons/' + settingsService.getMap().Skin + '/ui/edit_icon.svg'"
            (click)="openAccountDialog()" />
    </div>
    <div class="dropdown-el" (click)="openSubscriptionsDialog()">
        <h5 class="dropdown-el-text">{{ languageService.languageJSON.Global_Subscriptions }}</h5>
        <img class="dropdown-el-icon subscription-icon"
            [src]="'/assets/icons/' + settingsService.getMap().Skin + '/ui/menu_icon_subscriptions.svg'" />
    </div>
    <div class="dropdown-el" [ngClass]="{'inactive': loadingMap}" (click)="openHistoryDialog();">
        <h5 class="dropdown-el-text">{{ languageService.languageJSON.Global_History }}</h5>
        <img class="dropdown-el-icon"
            [src]="'/assets/icons/' + settingsService.getMap().Skin + '/ui/menu_icon_history.svg'" />
    </div>
    <div class="dropdown-el" (click)="logout()">
        <h5 class="dropdown-el-text">{{ languageService.languageJSON.Global_Logout }}</h5>
        <img class="dropdown-el-icon" [src]="'/assets/icons/' + settingsService.getMap().Skin + '/ui/logout_icon.svg'"
            (click)="logout()" />
    </div>
</div>