import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { MapService } from 'src/app/services/map.service';
import { InputParamsService } from 'src/app/services/input-params.service';
import Map from 'ol/Map';
import View from 'ol/View';
import TileLayer from 'ol/layer/Tile';
import XYZ from 'ol/source/XYZ';
import LineString from 'ol/geom/LineString';
import Feature from 'ol/Feature';
import VectorLayer from 'ol/layer/Vector'
import VectorSource from 'ol/source/Vector';
import Style from 'ol/style/Style';
import Stroke from 'ol/style/Stroke';
import { fromLonLat, toLonLat } from 'ol/proj';
import { MapEvent, Overlay } from 'ol';
import { NavigationService } from 'src/app/services/navigation.service';
import { UtilsService } from 'src/app/services/utils.service';
import { LatLng, LatLngBounds } from 'leaflet';
import Fill from 'ol/style/Fill';
import { MobileResolutionService } from 'src/app/services/mobile-resolution.service';
import { timer, fromEvent } from 'rxjs';
import { first } from 'rxjs/operators';
import Polygon from 'ol/geom/Polygon';
import { easeIn, easeOut, linear } from 'ol/easing';
import { defaults as defaultControls, ScaleLine, defaults } from 'ol/control';
import { LocationService } from 'src/app/services/location.service';
import { WebviewService } from 'src/app/services/webview.service';
import { MapSkin, SettingsService } from 'src/app/services/settings.service';
import device from 'current-device';
import { ConsoleLoggerService } from 'src/app/services/console-logger.service';
import { LanguageService } from 'src/app/services/language.service';
import { LoggerService } from 'src/app/services/logger.service';
import { Coordinate } from 'ol/coordinate';
import Geometry from 'ol/geom/Geometry';
import { Incident, RoadSurfaceAlert, Route, RoutePlanElement } from 'src/app/models/route';
import { Position } from 'src/app/models/position';
import { ChargerProvider, ChargingStation, ChargingStationDetails } from 'src/app/models/charging-station';
import RenderEvent from 'ol/render/Event';
import { unByKey } from 'ol/Observable';
import { ActivatedRoute } from '@angular/router';
import { StackedModalsService } from 'src/app/services/stacked-modals.service';
import { NavigationRestartDialogComponent } from '../modals/navigation-restart-dialog/navigation-restart-dialog.component';
import { MapDrawerHelperService } from 'src/app/services/map-drawer-helper.service';
import { AccountService } from 'src/app/services/account.service';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss']
})

export class NavigationComponent implements OnInit {

  constructor(private mapService: MapService, private inputParamsService: InputParamsService, private navigationService: NavigationService,
    private utilsService: UtilsService, private cdr: ChangeDetectorRef, public mobileResolutionService: MobileResolutionService,
    private locationService: LocationService, public webviewService: WebviewService, public settingsService: SettingsService,
    private consoleLoggerService: ConsoleLoggerService, public languageService: LanguageService, private loggerService: LoggerService,
    private activatedRoute: ActivatedRoute, private stackedModalsService: StackedModalsService, private mapHelperDrawerService: MapDrawerHelperService,
    private accountService: AccountService) {
  }

  map: Map;
  olMap: any;
  scale: ScaleLine;
  routeLayer: VectorLayer<VectorSource<Feature<Geometry>>>;
  turnInstructionLayer: VectorLayer<VectorSource<Feature<Geometry>>>;
  turnArrowPos: any;
  turnInstructionArrowLayer: VectorLayer<VectorSource<Feature<Geometry>>>;
  markerLayer: VectorLayer<VectorSource<Feature<Geometry>>>;
  tileEVAdminLayer: TileLayer<XYZ>;
  mapTileLayer: TileLayer<XYZ>;
  navigationActive: boolean = false;
  cursorOverlay: Overlay;
  gpsCursorOverlay: Overlay;
  popupOverlay: Overlay;

  watchPositionId: any;
  replayMode: boolean = false;
  routeData: Route = null;
  lastTurnRouteId: number;
  subscriptions: any = [];
  locationSubscription: any;
  mobileResolution: boolean = false;
  replayTimerPeriods = [1000, 750, 500, 250, 150, 50];
  replayTimerSpeeds = [0, 3, 10, 20, 30, 40];
  replayTimerPeriodIndex: number = 1;

  fakeGpsIterationIndex: number = 0;
  pointsWithTime: { mPosition: number, mDateTime: number, mHeight: number, mBearing: number, mSpeed: number }[] = [];
  waypointsArray: Overlay[] = [];
  tileVer = "5";

  darkMapUrl = 'https://tiles.gpstuner.net/tile_evdark_en_v2/{z}/{x}/{y}.png?ver=' + this.tileVer;
  lightMapUrl = 'https://tiles.gpstuner.net/tile_evlight_en_v2/{z}/{x}/{y}.png?ver=' + this.tileVer;
  darkAdminUrl = 'https://tiles.gpstuner.net/tile_evdark_admin_en_v2/{z}/{x}/{y}.png?ver=' + this.tileVer;
  lightAdminUrl = 'https://tiles.gpstuner.net/tile_evlight_admin_en_v2/{z}/{x}/{y}.png?ver=' + this.tileVer;

  ngOnInit() {
    if (this.activatedRoute?.snapshot?.routeConfig?.path == "demo") {
      this.replayMode = true;
    }
    // init map
    this.routeLayer = new VectorLayer({
      source: new VectorSource()
    });

    this.turnInstructionLayer = new VectorLayer({
      source: new VectorSource()
    });

    this.turnInstructionArrowLayer = new VectorLayer({
      source: new VectorSource()
    });

    this.markerLayer = new VectorLayer({
      source: new VectorSource()
    });

    const maptileUrl = this.settingsService.getMap().Skin == MapSkin.Light ? this.lightMapUrl : this.darkMapUrl;
    const maptileLayer = new TileLayer({
      source: new XYZ({
        url: maptileUrl,
        maxZoom: 18,
        minZoom: 3
      }),
    });

    this.mapTileLayer = maptileLayer;

    const adminUrl = this.settingsService.getMap().Skin == MapSkin.Light ? this.lightAdminUrl : this.darkAdminUrl;
    const adminLayer = new TileLayer({
      source: new XYZ({
        url: adminUrl,
        maxZoom: 11,
        minZoom: 3
      }),
    });
    this.tileEVAdminLayer = adminLayer;

    const mapBounds: LatLngBounds = this.mapService.getMapBounds();
    const Center = mapBounds.getCenter();

    this.map = new Map({
      target: 'map',
      layers: [
        this.mapTileLayer,
        this.tileEVAdminLayer,
        this.routeLayer,
        this.turnInstructionLayer,
        this.turnInstructionArrowLayer,
        this.markerLayer
      ],
      view: new View({
        center: fromLonLat([Center.lng, Center.lat]),
        maxZoom: 18,
        zoom: 18,
        minZoom: 3
      }),
      controls: defaults({
        zoom: false,
        rotate: false
      })
    });

    this.setScale();
    this.olMap = document.getElementById('map');

    this.map.getView().on('change:rotation', this.changerotationEvt);
    this.map.on('moveend', this.changeZoomEvt);
    this.map.on('pointermove', this.changeZoomEvt);
    this.map.on('click', this.mapClickEvt);
    onwheel = (event) => { this.pointerdragEvt(); };

    this.subscriptions.push(this.navigationService.ObservableNextTurn.subscribe((resp) => {
      if (resp && this.routeData?.RoutePlan && resp.NextTurnInstruction.RoutePointIdxStart > 0 && resp.NextTurnInstruction.RoutePointIdxStart != this.lastTurnRouteId) {
        if (resp.NextTurnInstruction.Id != "ARRIVE" && resp.NextTurnInstruction.Id != "ARRIVE_TO_WAYPOINT") {
          this.lastTurnRouteId = resp.NextTurnInstruction.RoutePointIdxStart;
          // draw turn to map

          const turnPoints = [];
          let remainingDist = 50;

          let turnStart = this.lastTurnRouteId;
          let turnEnd = resp.NextTurnInstruction.RoutePointIdxEnd;

          if (this.routeData.Turns.length) {
            // roundabout
            if (this.routeData.Turns[resp.NextTurnInstruction.Idx].TypeIndex == 94) {
              turnEnd = this.routeData.Turns[resp.NextTurnInstruction.Idx + 1].RoutePointIdxStart;
            }

            if (this.routeData.Turns[resp.NextTurnInstruction.Idx].TypeIndex == 95) {
              turnStart = this.routeData.Turns[resp.NextTurnInstruction.Idx - 1].RoutePointIdxStart;
            }

            // combined turns
            let combinedTurnIdx = resp.NextTurnInstruction.Idx;

            while (combinedTurnIdx - 1 >= 0 && this.routeData.Turns[combinedTurnIdx - 1].TypeIndex >= 12 && this.routeData.Turns[combinedTurnIdx - 1].TypeIndex <= 92) {
              combinedTurnIdx--;
              turnStart = this.routeData.Turns[combinedTurnIdx].RoutePointIdxStart;
            }

            combinedTurnIdx = resp.NextTurnInstruction.Idx;

            while (combinedTurnIdx + 1 < this.routeData.Turns.length && this.routeData.Turns[combinedTurnIdx].TypeIndex >= 12 && this.routeData.Turns[combinedTurnIdx].TypeIndex <= 92) {
              combinedTurnIdx++;
              turnEnd = this.routeData.Turns[combinedTurnIdx].RoutePointIdxStart;
            }
          }

          // add turn center point 
          turnPoints.push([this.routeData.RoutePoints[turnStart].Location.lng, this.routeData.RoutePoints[turnStart].Location.lat]);

          // adding points before turn
          let pointIdx = turnStart;

          while (remainingDist > 0) {
            remainingDist -= this.utilsService.distanceBetweenCoordinates([this.routeData.RoutePoints[pointIdx].Location.lat, this.routeData.RoutePoints[pointIdx].Location.lng],
              [this.routeData.RoutePoints[pointIdx - 1].Location.lat, this.routeData.RoutePoints[pointIdx - 1].Location.lng]);
            pointIdx--;
            turnPoints.unshift([this.routeData.RoutePoints[pointIdx].Location.lng, this.routeData.RoutePoints[pointIdx].Location.lat]);
            if (pointIdx <= 0 && remainingDist > 0) {
              break;
            }
          }

          let lastRemainingDist;
          let alpha;
          let lastLon;
          let lastLat;

          if (remainingDist < 0) {
            lastRemainingDist = this.utilsService.distanceBetweenCoordinatesLonLat(turnPoints[0], turnPoints[1]);
            alpha = (lastRemainingDist + remainingDist) / lastRemainingDist;
            lastLon = this.utilsService.Lerpd(turnPoints[0][0], turnPoints[1][0], 1 - alpha);
            lastLat = this.utilsService.Lerpd(turnPoints[0][1], turnPoints[1][1], 1 - alpha);
            turnPoints[0] = [lastLon, lastLat];
          }

          // adding points after turn
          for (let i = turnStart + 1; i <= turnEnd; i++) {
            if (i < this.routeData.RoutePoints.length) {
              turnPoints.push([this.routeData.RoutePoints[i].Location.lng, this.routeData.RoutePoints[i].Location.lat]);
            }
          }

          pointIdx = turnEnd;
          remainingDist = 50;
          while (remainingDist > 0 && pointIdx < this.routeData.RoutePoints.length - 1) {
            remainingDist -= this.utilsService.distanceBetweenCoordinates([this.routeData.RoutePoints[pointIdx].Location.lat, this.routeData.RoutePoints[pointIdx].Location.lng],
              [this.routeData.RoutePoints[pointIdx + 1].Location.lat, this.routeData.RoutePoints[pointIdx + 1].Location.lng]);
            pointIdx++;
            turnPoints.push([this.routeData.RoutePoints[pointIdx].Location.lng, this.routeData.RoutePoints[pointIdx].Location.lat]);
          }

          if (remainingDist < 0) {
            lastRemainingDist = this.utilsService.distanceBetweenCoordinatesLonLat(turnPoints[turnPoints.length - 2], turnPoints[turnPoints.length - 1]);
            alpha = (lastRemainingDist + remainingDist) / lastRemainingDist;
            lastLon = this.utilsService.Lerpd(turnPoints[turnPoints.length - 2][0], turnPoints[turnPoints.length - 1][0], alpha);
            lastLat = this.utilsService.Lerpd(turnPoints[turnPoints.length - 2][1], turnPoints[turnPoints.length - 1][1], alpha);
            turnPoints[turnPoints.length - 1] = [lastLon, lastLat];
          }

          const route = new LineString(turnPoints).transform('EPSG:4326', 'EPSG:3857');

          const turnFeatureOutline = new Feature({
            type: 'route',
            geometry: route
          });
          turnFeatureOutline.setStyle(new Style({
            stroke: new Stroke({
              width: 17, color: [24, 172, 233]
            })
          }));

          const turnFeature = new Feature({
            type: 'route',
            geometry: route
          });
          turnFeature.setStyle(new Style({
            stroke: new Stroke({
              width: 12, color: [255, 255, 255, 1]
            })
          }));

          const vectorSource = new VectorSource({
            features: [turnFeatureOutline, turnFeature]
          });
          this.turnInstructionLayer.setSource(vectorSource);

          const angle = this.utilsService.angleFromCoordinate(turnPoints[turnPoints.length - 2][1], turnPoints[turnPoints.length - 2][0],
            turnPoints[turnPoints.length - 1][1], turnPoints[turnPoints.length - 1][0]);
          this.turnArrowPos = [turnPoints[turnPoints.length - 1], angle];
          this.setTurnArrow();
        }
        else {
          this.lastTurnRouteId = resp.NextTurnInstruction.Idx;
          this.turnInstructionLayer.setSource(new VectorSource());
          this.turnInstructionArrowLayer.setSource(new VectorSource());
          this.turnArrowPos = null;
        }
      }
    }));

    this.subscriptions.push(this.navigationService.ObservableSetCenter.subscribe((resp) => {
      if (resp) {
        this.setToCenter();
      }
    }));

    this.subscriptions.push(this.navigationService.ObservableChangeNavigation.subscribe((resp) => {
      if (resp) {
        if (resp == "replan") {
          this.planRouteWithCurrentPostion();
        }
        else if (resp == "stop") {
          this.stopNavigation();
        }
        else if (resp == "arrive") {
          this.stopNavigation();
        }
        else if (resp == "exit") {
          if (this.replayMode) {
            if (this.replayTimer) {
              this.replayTimer.unsubscribe();
            }
          }
        }
      }
    }));

    this.subscriptions.push(this.mobileResolutionService.ObservableMobileResolution.subscribe((resp) => {
      if (resp != null && resp != undefined) {
        this.mobileResolution = resp;
        this.cdr.detectChanges();
      }
    }));

    this.subscriptions.push(this.settingsService.ObservableMap.subscribe((mapsettings) => {
      if (mapsettings) {
        let skin: string = mapsettings.Skin;
        if (skin && this.routeData?.RoutePoints && this.routeData?.Turns && this.routeData?.RoutePlan) {
          if (skin == "light") {
            const maptileLayer = new TileLayer({
              source: new XYZ({
                url: this.lightMapUrl,
                maxZoom: 18,
                minZoom: 3,
              }),
            });
            this.mapTileLayer.setSource(maptileLayer.getSource());
            const adminLayer = new TileLayer({
              source: new XYZ({
                url: this.lightAdminUrl,
                maxZoom: 11,
                minZoom: 3,
              }),
            });
            this.tileEVAdminLayer.setSource(adminLayer.getSource());
          }
          else {
            const maptileLayer = new TileLayer({
              source: new XYZ({
                url: this.darkMapUrl,
                maxZoom: 18,
                minZoom: 3,
              }),
            });
            this.mapTileLayer.setSource(maptileLayer.getSource());
            const adminLayer = new TileLayer({
              source: new XYZ({
                url: this.darkAdminUrl,
                maxZoom: 11,
                minZoom: 3,
              }),
            });
            this.tileEVAdminLayer.setSource(adminLayer.getSource());
          }

          this.drawRouteToMap();
          this.cursorOverlay = null;
          this.DrawCursorToMap(this.lastCoord);
        }
      }
    }));

    this.subscriptions.push(this.settingsService.ObservableUnit.subscribe((resp) => {
      if (resp) {
        this.setScale();
      }
    }));

    this.subscriptions.push(this.navigationService.ObservableNextWaypointIdx.subscribe((resp) => {
      if (resp) {
        this.setInactiveWaypoints(resp);
      }
    }));

    this.subscriptions.push(this.mapService.ObservableSetChargingStationRecalc.subscribe((resp) => {
      if (resp) {
        this.inputParamsService.paramsUpdate();
      }
    }));


    this.subscriptions.push(this.navigationService.ObservableOpenNavigationRestartDialog.subscribe((resp) => {
      if (resp) {
        this.stackedModalsService.openModal(NavigationRestartDialogComponent, { autoFocus: false });
        this.navigationService.ObservableOpenNavigationRestartDialog.next(false);
      }
    }));

    document.addEventListener("keydown", (evt: any) => {
      if (evt.key == "s" && this.replayTimerPeriodIndex != -1) {
        this.replayTimerPeriodIndex--;
        this.consoleLoggerService.log("REPLAY LEVEL: " + this.replayTimerPeriodIndex);
      }
      if (evt.key == "w" && this.replayTimerPeriodIndex < this.replayTimerPeriods.length - 1) {
        this.replayTimerPeriodIndex++;
        this.consoleLoggerService.log("REPLAY LEVEL: " + this.replayTimerPeriodIndex);
        if (this.replayTimerPeriodIndex == 0) {
          this.fakeGpsIteration();
        }
      }
    });

    if (this.replayMode && this.navigationService.CurrentPoint) {
      this.locationService.setLastPosition(new Position(this.inputParamsService.getWaypointsParams()[0].lat,
        this.inputParamsService.getWaypointsParams()[0].lng, 0, 0, 0, 0, 0, 0));
    }
    this.planRouteWithCurrentPostion();
  }

  setScale() {
    if (this.scale) {
      this.map.removeControl(this.scale);
    }
    this.scale = new ScaleLine({ units: this.settingsService.getUnit().Distance, minWidth: 42 })
    this.map.addControl(this.scale);
  }

  setTurnArrow() {
    let step = 0;
    if (this.lastZoom >= 15) {
      step = 0.00004 * Math.pow(2, (18 - this.lastZoom));
    }
    if (step == 0) {
      this.turnInstructionArrowLayer.setSource(new VectorSource());
    }
    else {
      const pos1 = fromLonLat([this.turnArrowPos[0][0] - (2 * step), this.turnArrowPos[0][1] - (1.5 * step)]);
      const pos2 = fromLonLat([this.turnArrowPos[0][0] - (1 * step), this.turnArrowPos[0][1] - (1.5 * step)]);
      const pos3 = fromLonLat([this.turnArrowPos[0][0] - (1 * step), this.turnArrowPos[0][1]]);
      const pos4 = fromLonLat([this.turnArrowPos[0][0], this.turnArrowPos[0][1]]);
      const pos5 = fromLonLat([this.turnArrowPos[0][0] + (1 * step), this.turnArrowPos[0][1]]);
      const pos6 = fromLonLat([this.turnArrowPos[0][0] + (1 * step), this.turnArrowPos[0][1] - (1.5 * step)]);
      const pos7 = fromLonLat([this.turnArrowPos[0][0] + (2 * step), this.turnArrowPos[0][1] - (1.5 * step)]);
      const pos8 = fromLonLat([this.turnArrowPos[0][0], this.turnArrowPos[0][1] + (1.5 * step)]);
      const cord = [pos1, pos2, pos3, pos4, pos5, pos6, pos7, pos8, pos1];

      const polyouter = new Polygon([cord]);
      polyouter.rotate(this.utilsService.toRad(-this.turnArrowPos[1]), fromLonLat(this.turnArrowPos[0]));

      const featureouter = new Feature(polyouter);
      featureouter.setStyle(new Style({
        stroke: new Stroke({
          color: [24, 172, 233],
          width: 6
        }),
        zIndex: 1
      }));

      const pos1b = fromLonLat([this.turnArrowPos[0][0] - (2 * step), this.turnArrowPos[0][1] - (1.5 * step)]);
      const pos2b = fromLonLat([this.turnArrowPos[0][0] + (2 * step), this.turnArrowPos[0][1] - (1.5 * step)]);
      const pos3b = fromLonLat([this.turnArrowPos[0][0], this.turnArrowPos[0][1] + (1.5 * step)]);
      const cordb = [pos1b, pos2b, pos3b, pos1b];
      const polyone = new Polygon([cordb]);
      polyone.rotate(this.utilsService.toRad(-this.turnArrowPos[1]), fromLonLat(this.turnArrowPos[0]));

      const featureone = new Feature(polyone);
      featureone.setStyle(new Style({
        fill: new Fill({
          color: 'white'
        }),
        zIndex: 2
      }));

      const vectorSource = new VectorSource({
        features: [featureouter, featureone]
      });
      this.turnInstructionArrowLayer.setSource(vectorSource);
    }
  }

  planRouteWithCurrentPostion() {
    if (!this.replayMode) {
      const lastPosition: Position = this.locationService.getLastPosition();
      if (lastPosition) {
        this.navigationService.LastReplanTime = Date.now();
        this.inputParamsService.setStartCoordsParams(new LatLng(lastPosition.Latitude, lastPosition.Longitude));
        if (!this.navigationService.slidersValueChangedIn15seconds) {
          this.inputParamsService.paramsUpdate();
        }
      }
    }
    else {
      this.navigationService.LastReplanTime = Date.now();

      if (this.navigationService.CurrentPoint) {
        this.inputParamsService.setStartCoordsParams(new LatLng(this.navigationService.CurrentPoint.Latitude, this.navigationService.CurrentPoint.Longitude));
      }
      else {
        this.inputParamsService.setStartCoordsParams(this.inputParamsService.getStartCoordsParams());
      }
      this.inputParamsService.paramsUpdate();
    }
  }

  setPlannedRoute(routeData: Route) {
    this.loggerService.successfulReplanHttpCallEvent();
    this.routeData = routeData;
    this.lastTurnRouteId = null;
    this.drawRouteToMap();
    this.initNavigation();
  }

  drawRouteToMap() {
    // remove previous drawing
    this.map.getOverlays().getArray().slice(0).forEach((overlay) => {
      this.map.removeOverlay(overlay);
    })
    const featureList: any = [];

    const locations = this.routeData.RoutePoints.map((routeEl) => { return [routeEl.Location.lng, routeEl.Location.lat] });
    if (this.settingsService.getMap().Skin == MapSkin.Light) {
      // grey outher
      const route = new LineString(locations).transform('EPSG:4326', 'EPSG:3857');
      const routeFeature = new Feature({
        type: 'route',
        geometry: route
      });

      routeFeature.setStyle(new Style({
        stroke: new Stroke({
          width: 15, color: [204, 204, 204, 1]
        })
      }));

      featureList.push(routeFeature);
    }

    // white line
    const route = new LineString(locations).transform('EPSG:4326', 'EPSG:3857');
    const routeFeature = new Feature({
      type: 'route',
      geometry: route
    });

    routeFeature.setStyle(new Style({
      stroke: new Stroke({
        width: 13, color: [255, 255, 255, 1]
      })
    }));

    featureList.push(routeFeature);

    // colored line
    for (let i = 1; i < this.routeData.RoutePoints.length; i++) {
      const route = new LineString([[this.routeData.RoutePoints[i - 1].Location.lng, this.routeData.RoutePoints[i - 1].Location.lat],
      [this.routeData.RoutePoints[i].Location.lng, this.routeData.RoutePoints[i].Location.lat]]).transform('EPSG:4326', 'EPSG:3857');
      const routeFeature = new Feature({
        type: 'route',
        geometry: route
      });
      routeFeature.setStyle(new Style({
        stroke: new Stroke({
          width: 7, color: this.utilsService.getColorForJamFactor(this.routeData.RoutePoints[i].JamFactor)
        })
      }));

      featureList.push(routeFeature);
    }

    // add waypoints, endmarker
    let waypointIdx = 0;
    this.waypointsArray = [];

    this.routeData.Turns.forEach((turn) => {
      if (turn.TypeIndex == 103) {
        waypointIdx++;
        // waypoint marker icon
        let wp = this.createWaypoint(this.routeData.RoutePoints[turn.RoutePointIdxStart].Location, waypointIdx);
        this.waypointsArray.push(wp);
        this.map.addOverlay(wp);
      }
    });

    this.map.addOverlay(this.createEndMarker(this.routeData.RoutePoints[this.routeData.RoutePoints.length - 1].Location));

    const singleAndMultipleChargingStations = this.mapService.getSingleAndMultipleChargingStations(this.routeData.RoutePlan);

    singleAndMultipleChargingStations.SingleChargingStations.forEach((charger: RoutePlanElement) => {
      this.map.addOverlay(this.createChargingStationOnRouteMarker(charger));
    });

    singleAndMultipleChargingStations.MultiChargingStations.forEach((multipleCharger: RoutePlanElement[]) => {
      this.map.addOverlay(this.createMultiChargingStationOnRouteMarker(multipleCharger));
    });

    // Road Surface Alerts
    this.routeData.RoadSurfaceAlerts.forEach(alert => {
      const alertIcon = this.mapHelperDrawerService.getRoadSurfaceAlertIcon(alert, this.settingsService.getMap().Skin);

      const alertHtmlElement = document.createElement("div");
      alertHtmlElement.setAttribute("style", "width: 24px; height: 24px; margin-left: -12px; margin-top: -12px;");
      alertHtmlElement.innerHTML = alertIcon.iconHtml;

      const alertIconOverlay = new Overlay({
        position: fromLonLat([alert.Location.lng, alert.Location.lat]),
        element: alertHtmlElement,
        stopEvent: false
      });

      alertHtmlElement.onclick = () => {
        this.drawMapPopup(fromLonLat([alert.Location.lng, alert.Location.lat]), alertIcon.tooltip);
      };
      this.map.addOverlay(alertIconOverlay);
    });

    // Draw Incidents
    this.routeData.Incidents.forEach(incident => {
      const incidentIcon = this.mapHelperDrawerService.getIncidentIcon(incident, this.settingsService.getMap().Skin);

      const incidentHtmlElement = document.createElement("div");
      incidentHtmlElement.setAttribute("style", "width: 24px; height: 24px; margin-left: -12px; margin-top: -12px;");
      incidentHtmlElement.innerHTML = incidentIcon.iconHtml;

      const incidentIconOverlay = new Overlay({
        position: fromLonLat([incident.Location.lng, incident.Location.lat]),
        element: incidentHtmlElement,
        stopEvent: false
      });

      incidentHtmlElement.onclick = () => {
        this.drawMapPopup(fromLonLat([incident.Location.lng, incident.Location.lat]), incidentIcon.tooltip);
      };
      this.map.addOverlay(incidentIconOverlay);
    });

    const source = new VectorSource({
      features: featureList
    });

    this.routeLayer.setSource(source);
  }

  setInactiveWaypoints(inactiveIdx) {
    if (inactiveIdx <= this.waypointsArray.length) {
      for (let i = 0; i < inactiveIdx; i++) {
        (this.waypointsArray[i] as Overlay).getElement().className = "grayscale";
      }
    }

    this.inputParamsService.deleteWaypointWithoutReplan(1);
  }

  createEndMarker(latLon: LatLng): Overlay {
    const divIconHtml = "<img src='assets/icons/" + this.settingsService.getMap().Skin + "/map/route_point_target.svg'>";
    const div = document.createElement('div');
    div.setAttribute("style", "width: 84px; height: 84px; margin-left: -42px; margin-top: -42px;");
    div.innerHTML = divIconHtml;

    return new Overlay({
      position: fromLonLat([latLon.lng, latLon.lat]),
      element: div,
      stopEvent: false
    });
  }

  createWaypoint(latLon: LatLng, waypointIdx: number): Overlay {
    let divIconHtml = "<div class='waypoint-icon-outer'><div class='waypoint-icon'>";
    divIconHtml += "<img src='assets/icons/" + this.settingsService.getMap().Skin + "/map/route_point_waypoint.svg'></div>";
    divIconHtml += "<div class='waypoint-idx'>" + waypointIdx + "</div></div>";
    const div = document.createElement('div');
    div.setAttribute("style", "width: 84px; height: 84px; margin-left: -42px; margin-top: -42px; position: relative;");
    div.innerHTML = divIconHtml;

    return new Overlay({
      position: fromLonLat([latLon.lng, latLon.lat]),
      element: div,
      stopEvent: false
    });
  }

  createChargingStationOnRouteMarker(charger: RoutePlanElement): Overlay {
    let chargingStationDivIconHtml = "<div class='map-charger'>";

    // charge time box
    let chargeTimeMin: any = charger.ChargeTimeMin;
    let chargeTimeHour = charger.ChargeTimeHour;
    if (chargeTimeMin.toString().length < 2) {
      chargeTimeMin = "0" + chargeTimeMin.toString();
    }
    if (chargeTimeHour > 0) {
      if (chargeTimeMin == "00") {
        chargingStationDivIconHtml += `<div class='charge-time'><div class='charge-time-inner'> <h4>${chargeTimeHour}</h4> <small>${this.languageService.languageJSON.Unit_Hour_Short}</small> <h4></div></div>`;
      }
      else {
        chargingStationDivIconHtml += `<div class='charge-time'><div class='charge-time-inner'> <h4>${chargeTimeHour}</h4> <small>${this.languageService.languageJSON.Unit_Hour_Short}</small> <h4>${chargeTimeMin}</h4><small>${this.languageService.languageJSON.Unit_Minute_Short}</small></div></div>`;
      }
    }
    else {
      chargingStationDivIconHtml += `<div class='charge-time'><div class='charge-time-inner'> <h4>${chargeTimeMin}</h4><small>${this.languageService.languageJSON.Unit_Minute_Short}</small></div></div>`;
    }

    let iconSvg = "assets/icons";

    if (this.settingsService.getMap().Skin == MapSkin.Light) {
      iconSvg += "/light/map/";
    }
    else {
      iconSvg += "/dark/map/";
    }

    // charge icon
    if (charger.ChargePower > 35) {
      iconSvg += "charging_station_3";
    }
    else if (charger.ChargePower > 11) {
      iconSvg += "charging_station_1";
    }
    else {
      iconSvg += "charging_station_2";
    }

    chargingStationDivIconHtml += "<div class='charge-icon'><img src='" + iconSvg + ".svg  '/></div><div class='charge-index'><img src='assets/icons/default/map/chargingstation_icon.svg'/>" + (charger.ChargerIndex + 1) + " </div></div>";

    let div = document.createElement('div');
    div.setAttribute("style", "width: 84px; height: 108px; margin-left: -42px; margin-top: -66px; position: relative;");
    div.innerHTML = chargingStationDivIconHtml;

    const chargingStationOverlay = new Overlay({
      position: fromLonLat([charger.Location.lng, charger.Location.lat]),
      element: div,
      stopEvent: false
    });

    div.onclick = () => {
      this.loadChargingStationPopup(fromLonLat([charger.Location.lng, charger.Location.lat]), charger.ChargingStationId, 80);
    };

    return chargingStationOverlay;
  }

  createMultiChargingStationOnRouteMarker(multipleCharger: RoutePlanElement[]): Overlay {
    // chargining station divicon
    let chargingStationDivIconHtml = "<div class='map-charger'><div class='charge-time'>";

    // charge time box
    multipleCharger.forEach((charger: RoutePlanElement) => {
      chargingStationDivIconHtml += "<div class='charge-time-inner'><span class='multi-charge-index'><img class='charge-index-icon' src='assets/icons/default/map/chargingstation_icon.svg'/>" + (charger.ChargerIndex + 1) + "</span>";
      let chargeTimeMin: any = charger.ChargeTimeMin;
      const chargeTimeHour = charger.ChargeTimeHour;
      if (chargeTimeMin.toString().length < 2) {
        chargeTimeMin = "0" + chargeTimeMin.toString();
      }
      if (chargeTimeHour > 0) {
        if (chargeTimeMin == "00") {
          chargingStationDivIconHtml += `<h4>${chargeTimeHour}</h4> <small>${this.languageService.languageJSON.Unit_Hour_Short}</small> <h4></div>`;
        }
        else {
          chargingStationDivIconHtml += `<h4>${chargeTimeHour}</h4> <small>${this.languageService.languageJSON.Unit_Hour_Short}</small> <h4>${chargeTimeMin}</h4><small>${this.languageService.languageJSON.Unit_Minute_Short}</small></div>`;
        }
      }
      else {
        chargingStationDivIconHtml += `<h4>${chargeTimeMin}</h4><small>${this.languageService.languageJSON.Unit_Minute_Short}</small></div>`;
      }
    });


    let iconSvg;
    // charge icon
    if (multipleCharger[0].ChargePower > 35) {
      iconSvg = "charging_station_3";
    }
    else if (multipleCharger[0].ChargePower > 11) {
      iconSvg = "charging_station_1";
    }
    else {
      iconSvg = "charging_station_2";
    }

    chargingStationDivIconHtml += "</div><div class='charge-icon'><img src='assets/icons/" + this.settingsService.getMap().Skin + "/map/" + iconSvg + ".svg  '/></div><div class='charge-index'><img src='assets/icons/default/map/chargingstation_icon.svg'/></div></div>";

    let div = document.createElement('div');
    div.setAttribute("style", "width: 84px; height: 108px; margin-left: -42px; margin-top: -66px; position: relative;");
    div.innerHTML = chargingStationDivIconHtml;

    const chargingStationOverlay = new Overlay({
      position: fromLonLat([multipleCharger[0].Location.lng, multipleCharger[0].Location.lat]),
      element: div,
      stopEvent: false
    });

    div.onclick = () => {
      this.loadChargingStationPopup(fromLonLat([multipleCharger[0].Location.lng, multipleCharger[0].Location.lat]), multipleCharger[0].ChargingStationId, 80);
    };

    return chargingStationOverlay;
  }

  private loadChargingStationPopup(coordinate: Coordinate, chargingStationId: string, paddingBottom: number) {
    this.mapService.getChargingStationDetail([chargingStationId]).subscribe((resp) => {
      this.drawChargingStationPopup(coordinate, resp[0], paddingBottom);
    });
  }

  private drawChargingStationPopup(coordinate: Coordinate, chargingStation: ChargingStationDetails, paddingBottom: number): void {
    let csPopupElement = document.createElement('div');

    if (device.mobile()) {
      const mapCenterPoint = this.map.getPixelFromCoordinate(fromLonLat([chargingStation.lon, chargingStation.lat]));
      const height = this.map.getSize()[1];
      const calculatedPoint = [mapCenterPoint[0], mapCenterPoint[1] - height * 0.25];

      this.map.getView().animate(
        {
          center: this.map.getCoordinateFromPixel(calculatedPoint),
          easing: easeOut,
          duration: 500
        }
      );
    }

    let tooltip = "<div class='map-tooltip arrow-to-bottom top rounded-body cs-tooltip'>";

    tooltip += `<div class="title"><h3>${chargingStation.title}</h3></div>`;
    tooltip += `<div class="body"><div class="info">`;
    if (chargingStation.opid != undefined && chargingStation.opid != null && chargingStation.opid != -1) {
      tooltip += `<h4>${this.mapService.getChargingOperatorByID(chargingStation.opid).name}</h4>`;
    }
    if (chargingStation.opid != undefined && chargingStation.opid != null && chargingStation.opid != -1) {
      tooltip += `<div class="info-el">
            <img class="info-image" src="assets/icons/${this.settingsService.getMap().Skin}/map/info_icon_web.svg" />
            <a target="_blank" href="${this.mapService.getChargingOperatorByID(chargingStation.opid).url}">${this.mapService.getChargingOperatorByID(chargingStation.opid).url}</a></div>`;
    }
    if (chargingStation.phone) {
      tooltip += `<div class="info-el">
            <img class="info-image" src="assets/icons/${this.settingsService.getMap().Skin}/map/info_icon_phone.svg" />
            <a target="_blank" href="tel:${chargingStation.phone}">${chargingStation.phone}</a></div>`;
    }
    if (chargingStation.cost) {
      if (this.utilsService.validURL(chargingStation.cost)) {
        tooltip += `<div class="info-el">
            <img class="info-image" src="assets/icons/${this.settingsService.getMap().Skin}/map/info_icon_price.svg" />
            <a target="_blank" href="${chargingStation.cost}">${chargingStation.cost}</a></div>`;
      }
      else {
        tooltip += `<div class="info-el">
            <img class="info-image" src="assets/icons/${this.settingsService.getMap().Skin}/map/info_icon_price.svg" />
            <span class="info-el-text">${chargingStation.cost}</span></div>`;
      }
    }
    if (chargingStation.provider = ChargerProvider.OCM) {
      const ocmId = this.utilsService.getOCMId(chargingStation.id);
      tooltip += `<div class="info-el">
            <img class="info-image" src="assets/icons/default/map/info_icon_ocm.svg" />
            <a target="_blank" href="https://openchargemap.org/site/poi/details/${ocmId}">${this.languageService.languageJSON.Map_Popup_MoreInfo}</a></div>`;
    }
    tooltip += `</div>`;
    for (let j = 0; j < chargingStation.plugs.length; j++) {
      let chargePower = Math.round(chargingStation.plugs[j].power * 10) / 10;
      let chargePowerText = "";

      let chargerType = chargingStation.plugs[j].type;

      let carPower = chargerType == 3 || chargerType == 5 || chargerType == 6 ? this.mapService.getSelectedCar().ChargePower : this.mapService.getSelectedCar().FastChargePower;

      if ((carPower > 0) && (carPower < chargePower)) {
        chargePowerText = chargePower + "kW (" + carPower + " kW)";
      }
      else {
        chargePowerText = chargePower + " kW";
      }
      /*if (j == chargingStation.plugs.length - 1) {
        tooltip += `<div class="plug last-plug">
              <img src="/assets/icons/${this.settingsService.getMap().Skin}/plug/${this.utilsService.chargingstationsImg[chargingStation.plugs[j].type - 1][0]}"/>
              <p> ${chargingStation.plugs[j].count} x ${this.utilsService.chargingstationsImg[chargingStation.plugs[j].type - 1][1]}
                | ${chargePowerText}</p></div>`;
      }
      else {
        tooltip += `<div class="plug">
              <img src="/assets/icons/${this.settingsService.getMap().Skin}/plug/${this.utilsService.chargingstationsImg[chargingStation.plugs[j].type - 1][0]}"/>
                <p> ${chargingStation.plugs[j].count} x ${this.utilsService.chargingstationsImg[chargingStation.plugs[j].type - 1][1]}
                 | ${chargePowerText}</p></div>`;
      }*/
      tooltip += `<div class="plug ${j == chargingStation.plugs.length - 1 ? 'last-plug' : ''} 
              ${chargingStation.plugs[j].available != undefined && this.accountService.getUserSubscriptionType().includes('proplus.') ? 'availability-info' : ''}">
              <div class="charger-icon"/><img src="/assets/icons/${this.settingsService.getMap().Skin}/plug/${this.utilsService.chargingstationsImg[chargingStation.plugs[j].type - 1][0]}"/>`;
      if (this.accountService.getUserSubscriptionType().includes('proplus.') && chargingStation.plugs[j].statusCount > 0) {
        tooltip += `<div class="availability ${chargingStation.plugs[j].available > 0 ? 'available' : 'not-available'}">${chargingStation.plugs[j].available}/${chargingStation.plugs[j].statusCount}</div>`;
      }
      tooltip += `</div><p> ${chargingStation.plugs[j].count} x ${this.utilsService.chargingstationsImg[chargingStation.plugs[j].type - 1][1]}
                | ${chargePowerText}</p></div>`;
    }

    tooltip += `</div></div>`;
    csPopupElement.innerHTML = tooltip;

    if (this.popupOverlay) {
      this.map.removeOverlay(this.popupOverlay);
    }

    this.popupOverlay = new Overlay({
      position: coordinate,
      element: csPopupElement
    });
    this.map.addOverlay(this.popupOverlay);

    this.popupOverlay.setOffset([0, -84]);
    this.setMapDragIn5Seconds(true);
  }

  private drawMapPopup(coordinate: Coordinate, text: string): void {
    let mapPopupElement = document.createElement('div');

    let tooltip = `<div class='map-tooltip cs-tooltip navigation-tooltip'>${text}</div>`;
    mapPopupElement.innerHTML = tooltip;

    if (this.popupOverlay) {
      this.map.removeOverlay(this.popupOverlay);
    }

    this.popupOverlay = new Overlay({
      position: coordinate,
      element: mapPopupElement
    });
    this.map.addOverlay(this.popupOverlay);

    this.popupOverlay.setOffset([-50, -35]);
    this.setMapDragIn5Seconds(true);
  }

  replayTimer: any;
  lastBearing: number = -9999;
  lastMapRotation: number = null;
  lastBearingPos: any;
  mapDragIn5Seconds: boolean = false;
  DraggingInterval: any;
  pointerdragEvt: any = () => {
    this.setMapDragIn5Seconds(true);
    this.cdr.detectChanges();
    clearTimeout(this.DraggingInterval);
    this.DraggingInterval = setTimeout(() => {
      this.lastRotation = this.utilsService.toRad(360 - this.lastBearing);
      this.setMapDragIn5Seconds(false);
      this.cdr.detectChanges();
    }, 5000);
  };
  changerotationEvt: any = (evt) => {
    if (document.getElementById("navi-icon") && this.lastBearing) {
      this.lastMapRotation = this.utilsService.toDeg(evt.target.values_.rotation);
      this.RotateCursor(this.lastMapRotation + this.lastBearing);
    }
  }
  lastZoom: number = 18;
  changeZoomEvt: any = (evt) => {
    const newZoom = this.map.getView().getZoom();
    if (newZoom != this.lastZoom) {
      this.lastZoom = newZoom;
      if (this.turnArrowPos) {
        this.setTurnArrow();
      }
    }
    if (newZoom > 11) {
      this.tileEVAdminLayer.setOpacity(0);
    }
    else {
      this.tileEVAdminLayer.setOpacity(1);
    }
  }

  mapClickEvt = (evt) => {
    if (this.popupOverlay) {
      this.map.removeOverlay(this.popupOverlay);
    }
  }

  private setMapDragIn5Seconds(dragged: boolean) {
    this.mapDragIn5Seconds = dragged;
    this.navigationService.ObservableMapDragIn5Seconds.next(dragged);
  }

  // N A V I G A T I O N
  initNavigation() {
    this.stopNavigation();

    this.turnInstructionLayer.setSource(new VectorSource());

    this.DrawCursorToMap([this.routeData.RoutePoints[0].Location.lat, this.routeData.RoutePoints[0].Location.lng]);

    this.lastBearingPos = [this.routeData.RoutePoints[0].Location.lng, this.routeData.RoutePoints[0].Location.lat];

    this.map.on('pointerdrag', this.pointerdragEvt);

    this.routeData.Turns = this.mapService.initTurnIconAndInstruction(this.routeData.Turns);

    this.navigationService.StartNavigation(this.routeData);

    if (this.replayMode) {
      this.FakeGpsNavigation();
    }
    else {
      this.RealGpsNavigation();
    }
  }

  private DrawCursorToMap(pos) {
    // cursor
    if (this.cursorOverlay == undefined) {
      let rhaCursorHtml;
      if (this.settingsService.getMap().Skin == MapSkin.Light) {
        rhaCursorHtml = "<img id='navi-icon' src='assets/icons/light/map/cursor.png' style='width: 50px; height: 50px;' />";
      }
      else {
        rhaCursorHtml = "<img id='navi-icon' src='assets/icons/dark/map/cursor.png' style='width: 50px; height: 50px;' />";
      }
      const div = document.createElement('div');
      div.setAttribute("style", "width: 50px; height: 50px; margin-left: -25px; margin-top: -25px;");
      div.innerHTML = rhaCursorHtml;


      this.cursorOverlay = new Overlay({
        position: fromLonLat([pos[1], pos[0]]),
        element: div,
        stopEvent: false
      });
    }

    this.map.addOverlay(this.cursorOverlay);
  }

  private RotateCursor(deg: number) {
    document.getElementById("navi-icon").setAttribute("style", "transform: rotate(" + deg + "deg); width: 50px; height: 50px;");
  }

  private RealGpsNavigation() {
    if (!this.webviewService.IsGPSTWebview()) {
      this.locationService.startWatchPosition();
    }
    this.locationSubscription = this.locationService.ObservableLastPosition.subscribe((position: Position) => {
      if (position) {
        this.success(position);
      }
    });
  }

  private FakeGpsNavigation() {
    if (this.replayTimer) {
      this.replayTimer.unsubscribe();
    }
    this.fakeGpsIterationIndex = 0;
    this.pointsWithTime = this.navigationService.generateFakeGpsData(this.routeData.RoutePoints);

    this.fakeGpsIteration();
    this.locationService.setFakeGPSReplayMode(true);

    this.locationSubscription = this.locationService.ObservableLastPosition.subscribe((position: Position) => {
      if (position) {
        this.success(position);
      }
    });
  }


  private setMapCenterFollowMode(centerLatLon: number[], rotation: number) {
    const newCenter = this.getMapCenterWithOffsetAndRotation(fromLonLat([centerLatLon[1], centerLatLon[0]]), rotation);
    this.map.getView().animate(
      {
        center: newCenter,
        easing: easeOut,
        rotation: rotation,
        duration: 500
      }
    );
  }

  private fakeGpsIteration() {
    const i = this.fakeGpsIterationIndex;
    if (this.pointsWithTime && i < this.pointsWithTime.length) {

      const position = new Position(this.pointsWithTime[i].mPosition[0], this.pointsWithTime[i].mPosition[1],
        this.replayTimerSpeeds[this.replayTimerPeriodIndex], 1, 1, 1, this.pointsWithTime[i].mBearing, new Date().getTime());

      this.locationService.setLastPosition(position);
      this.fakeGpsIterationIndex++;

      if (i == this.pointsWithTime.length - 1) {
        //this.navigationService.ObservableChangeNavigation.next("arrive");
        this.fakeGpsIterationIndex = 0;
        return;
      }

      if (this.replayTimerPeriods.length > this.replayTimerPeriodIndex && this.replayTimerPeriodIndex != -1) {
        this.replayTimer = timer(this.replayTimerPeriods[this.replayTimerPeriodIndex]).pipe(first()).subscribe(() => {
          this.fakeGpsIteration();
        });
      }
    }
    else {
      this.replayTimer.unsubscribe();
    }
  }

  lastCoord = null;
  lastRotation = null;

  private updateRotation(rotation: number, lastRotation: number, position: Position): boolean {
    return Math.abs(rotation - lastRotation) > this.utilsService.toRad(1) && this.utilsService.distanceBetweenCoordinates(this.lastBearingPos, [position.Latitude, position.Longitude]) > 10 && position.Speed >= 3
  }

  private success(position: Position) {
    const closestpoint = this.navigationService.GetClosestPoint([position.Latitude, position.Longitude]);
    const snappedCoords = closestpoint[0];

    let bearing = position.Heading;
    let actualBearing = position.Heading;

    if (this.lastCoord != null && this.lastCoord[0] != position.Latitude && this.lastCoord[1] != position.Longitude) {
      bearing = this.utilsService.angleFromCoordinate(this.lastCoord[0], this.lastCoord[1], position.Latitude, position.Longitude);
      actualBearing = bearing;
    }

    this.lastCoord = [position.Latitude, position.Longitude];

    let speed = 1;

    if (position.Speed != null) {
      speed = this.utilsService.mpsToKmph(position.Speed);
    }

    if (closestpoint[1] != -2) {
      bearing = this.navigationService.GetNavigationCursorAngle(closestpoint[1]);
    }

    this.lastBearing = bearing;
    this.animateOverlayPosition(fromLonLat([snappedCoords[1], snappedCoords[0]]), bearing, 1000);

    if (document.getElementById("navi-icon") && this.lastBearing && this.lastMapRotation) {
      this.RotateCursor(this.lastMapRotation + this.lastBearing);
    }

    this.navigationService.RefreshTarget({
      Latitude: position.Latitude,
      Longitude: position.Longitude,
      Speed: speed,
      HorizontalAccuracy: 3,
      Course: actualBearing,
      FirstRoutePoint: false
    });
  }

  animateOverlayPosition(newCursorPosition: Coordinate, bearing: number, duration: number): void {
    const start = +new Date();
    const actCursorPos = this.cursorOverlay.getPosition();
    const actMapPosition = this.map.getView().getCenter();
    const actMapRotation = this.map.getView().getRotation();
    let rotation = this.utilsService.toRad(360 - bearing);
    let change = this.shortestAnglePath(actMapRotation, rotation);

    const newMapPosition = this.getMapCenterWithOffsetAndRotation(newCursorPosition, rotation);

    const animate = (event: MapEvent | RenderEvent): void => {
      const time = +new Date();
      const elapsed = time - start;
      const fraction = elapsed / duration;

      if (fraction < 1) {
        // cursor
        const cursorX = actCursorPos[0] + fraction * (newCursorPosition[0] - actCursorPos[0]);
        const cursorY = actCursorPos[1] + fraction * (newCursorPosition[1] - actCursorPos[1]);
        this.cursorOverlay.setPosition([cursorX, cursorY]);

        if (!this.mapDragIn5Seconds) {
          // map position
          const mapX = actMapPosition[0] + fraction * (newMapPosition[0] - actMapPosition[0]);
          const mapY = actMapPosition[1] + fraction * (newMapPosition[1] - actMapPosition[1]);
          this.map.getView().setCenter([mapX, mapY]);

          let currentAngle = actMapRotation + change * fraction;
          this.map.getView().setRotation(currentAngle);
        }
        this.map.render();
      } else {
        this.cursorOverlay.setPosition(newCursorPosition);

        if (!this.mapDragIn5Seconds) {
          this.map.getView().setCenter(newMapPosition);
          this.map.getView().setRotation(rotation);
        }
        this.map.render();
        unByKey(listenerKey);
      }
    }

    // Set up the animation listener
    const listenerKey = this.map.on('postcompose', animate);

    // Start the animation
    this.map.render();
  }

  private shortestAnglePath(startAngle: number, endAngle: number): number {
    startAngle = this.utilsService.normalizeAngleInRad(startAngle);
    endAngle = this.utilsService.normalizeAngleInRad(endAngle);

    let difference = endAngle - startAngle;
    if (difference > Math.PI) difference -= 2 * Math.PI;
    if (difference < -Math.PI) difference += 2 * Math.PI;

    return difference;
  }

  private getMapCenterWithOffsetAndRotation(newPosition: Coordinate, rotation: number): Coordinate {
    const view = this.map.getView();
    const resolution = view.getResolution();
    let height = this.map.getSize()[1];
    let pixelOffsetY = 0;

    if (!device.mobile()) {
      if (this.mobileResolutionService.getMobileResolution()) {
        pixelOffsetY = 0.4 * height * resolution - 233 * resolution;
      }
      else {
        pixelOffsetY = 0.25 * height * resolution;
      }
    }
    else {
      if (this.mobileResolutionService.isPortrait()) {
        const panelElement = document.querySelector(".non-desktop .navigation-hud-bottom-panel");
        const panelElementBottomValue = window.getComputedStyle(panelElement).getPropertyValue("bottom");
        pixelOffsetY = 0.4 * height * resolution - (panelElement.clientHeight + parseFloat(panelElementBottomValue)) * resolution;
      }
      else {
        pixelOffsetY = 0.25 * height * resolution;
      }
    }

    // Define the offset for the cursor position
    const offsetX = 0; // Horizontal offset
    const offsetY = pixelOffsetY; // Vertical offset, negative to move it towards the bottom

    // Calculate the new position
    return [
      newPosition[0] + offsetX * Math.cos(rotation) - offsetY * Math.sin(rotation),
      newPosition[1] + offsetX * Math.sin(rotation) + offsetY * Math.cos(rotation)
    ];
  }

  private stopNavigation() {
    this.locationService.stopWatchPosition();
    if (this.locationSubscription) {
      this.locationSubscription.unsubscribe();
    }

    this.locationService.setFakeGPSReplayMode(false);
  }

  public setToCenter() {
    this.setMapDragIn5Seconds(false);
    this.cdr.detectChanges();
    if (this.lastCoord) {
      this.setMapCenterFollowMode(this.lastCoord, this.utilsService.toRad(360 - this.lastBearing));
    }
  }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    for (let i = 0; i < this.subscriptions.length; i++) {
      this.subscriptions[i].unsubscribe();
    }
    this.subscriptions = [];
    if (this.replayMode) {
      if (this.replayTimer) {
        this.replayTimer.unsubscribe();
      }
    }

    this.map.getView().un('change:rotation', this.changerotationEvt);
    this.map.un('pointerdrag', this.pointerdragEvt);
    this.map.un('moveend', this.changeZoomEvt);
    this.map.un('pointermove', this.changeZoomEvt);
    this.map.un('click', this.mapClickEvt);
    clearTimeout(this.DraggingInterval);
    this.stopNavigation();
  }
}