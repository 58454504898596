import { Component, OnInit, ViewChild, ChangeDetectionStrategy, ChangeDetectorRef, Input, HostListener, NgZone, ElementRef } from '@angular/core';
import { NgScrollbar } from 'ngx-scrollbar';
import 'leaflet';
import * as L from 'leaflet';
import { LatLng } from 'leaflet';
import { moveItemInArray } from '@angular/cdk/drag-drop';
import { skip } from 'rxjs/operators';
import { InputParamsService } from 'src/app/services/input-params.service';
import { MobileResolutionService } from 'src/app/services/mobile-resolution.service';
import { MapService } from 'src/app/services/map.service';
import { UtilsService } from 'src/app/services/utils.service';
import { ActivatedRoute } from '@angular/router';
import { NavigationService } from 'src/app/services/navigation.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { GpsErrorDialogComponent } from '../../modals/gps-error-dialog/gps-error-dialog.component';
import { ConfirmationDialogComponent } from '../../modals/confirmation-dialog/confirmation-dialog.component';
import { WebviewService } from 'src/app/services/webview.service';
import { SettingsService, Unit } from 'src/app/services/settings.service';
import { LanguageService } from 'src/app/services/language.service';
import { AccountService } from 'src/app/services/account.service';
import { SettingsDialogComponent } from '../../modals/settings-dialog/settings-dialog.component';
import { ConsoleLoggerService } from 'src/app/services/console-logger.service';
import { AccountDialogComponent } from '../../modals/account-dialog/account-dialog.component';
import { InfoDialogComponent } from '../../modals/info-dialog/info-dialog.component';
import { fromEvent, concatMap, takeUntil, Subscription } from 'rxjs';
import { MapElementType } from '../../map/map.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Route, RoutePlanElement, RoutePlanType, Turn } from 'src/app/models/route';
import { SearchedRouteElement } from '../../../models/searched-route-element';
import { RouteListElement } from 'src/app/models/route-list';
import { Position } from 'src/app/models/position';
import { Waypoint } from 'src/app/models/range-params';
import { LocationService } from 'src/app/services/location.service';

@Component({
  selector: 'app-info-sidebar',
  templateUrl: './info-sidebar.component.html',
  styleUrls: ['./info-sidebar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InfoSidebarComponent implements OnInit {
  @Input() windHider: boolean;
  @Input() loadingMap: string;
  @ViewChild('infoScrollbar', { static: true }) infoScrollbar: NgScrollbar;
  @ViewChild('paneDragOuter', { static: true }) paneDragOuter: ElementRef<HTMLElement>;
  @HostListener('window:resize') onResize(e) {
    this.cdr.detectChanges();
    this.infoSidebarHeight = this.getInfoSidebarDefaultHeight();
    this.cdr.detectChanges();
    this.infoScrollbar.update();

    if (!this.navigation && (window.innerWidth <= 1050 || window.innerHeight <= 700) && this.orientationPortrait != this.mobileResolutionService.isPortrait()) {
      this.orientationPortrait = this.mobileResolutionService.isPortrait();
      this.infoSidebarHeight = 218;
      this.infoSidebarSetMobileHeight = this.infoSidebarHeight;
      this.infoScrollbar.update();
      this.cdr.detectChanges();
    }
  }

  public isAuthorized: boolean = false;
  public mobileVisiblePanel: string = "";
  public mobileVisibleRoutePanel: string = "routepoints";
  public mobileResolution: boolean = undefined;
  public rhaSearchValue: string = "";
  public routeList: RouteListElement[] = [{
    display_name: "Karl-Liebknecht-Straße Berlin, Berlin, Germany",
    lat: 52.520008,
    lng: 13.404954,
    wp: -1,
    distance: 0
  }];
  public routeData: Route;
  public selectedMode: string = "rha";
  public searched: boolean = false;
  public navigation: boolean = false;
  public itineraryNextTurnIdx: number = -1;
  public failedPlan: boolean = false;
  public maxReachedWaypoint: number = 0;
  public noRoute: boolean = false;
  private subscriptions: Subscription[] = [];
  public multiwayScrollFromTop: number = 0;
  public demo: boolean = false;
  public replayMode: boolean = false;
  public roadLanesLength: number = 0;
  public emptySearchInput: boolean = false;
  public lastHudComputers: number = 1;
  private orientationPortrait: boolean = false;

  public itineraryDropdown: boolean = false;
  public infoSidebarSetMobileHeight: number = 218;
  public infoSidebarHeight: number = this.getInfoSidebarDefaultHeight();
  public activeInfoSidebarRouteElement: number = -1;

  /* display planned route default data, itinerary, charging plan */
  constructor(private inputParamsService: InputParamsService, public mobileResolutionService: MobileResolutionService, private mapService: MapService,
    private cdr: ChangeDetectorRef, public utilsService: UtilsService, private navigationService: NavigationService,
    public webviewService: WebviewService, public MatDialog: MatDialog, public settingsService: SettingsService,
    private _ngZone: NgZone, public languageService: LanguageService, public accountService: AccountService, private consoleLoggerService: ConsoleLoggerService,
    private snackBar: MatSnackBar, private activatedRoute: ActivatedRoute, private locationService: LocationService) {

    this.orientationPortrait = this.mobileResolutionService.isPortrait();

    // set rha routeList
    const waypointsStorage: any = localStorage.getItem("waypoints");
    if (waypointsStorage && waypointsStorage != 'null') {
      const waypoints = JSON.parse(waypointsStorage);

      if (waypoints.length == 1) {
        this.mapService.getReverseGeocode(waypoints[0].lat, waypoints[0].lng).subscribe((reversegeocode) => {
          if (reversegeocode) {
            this.inputParamsService.ObservableSetStartPointGeocode.next({
              display_name: reversegeocode,
              lat: waypoints[0].lat,
              lng: waypoints[0].lng,
              wp: 0
            });
          }
        }, () => { });
      }
    }

    if (this.activatedRoute?.snapshot?.routeConfig?.path == "demo") {
      this.demo = true;
    }
  }

  ngOnChanges(): void {
    if (this.infoScrollbar && this.mobileResolution != undefined) {
      this.infoScrollbar.update();
    }
  }

  ngOnInit(): void {
    this.subscriptions.push(this.settingsService.ObservableMap.pipe(skip(1)).subscribe((mapsettings) => {
      this.cdr.detectChanges();
    }));

    this.subscriptions.push(this.languageService.ObservableLanguageLoaded.subscribe((resp) => {
      if (resp) {
        if (this.selectedMode == "route" && this.routeData) {
          this.routeData.Turns = this.mapService.initTurnIconAndInstruction(this.routeData.Turns);
        }
        this.cdr.detectChanges();
      }
    }));

    if (this.activatedRoute?.snapshot?.routeConfig?.path == "demo") {
      this.replayMode = true;
    }

    // observe when get route is selected
    this.subscriptions.push(this.inputParamsService.ObservableSelectedMode.subscribe((resp) => {
      if (resp == "route") {
        // if the user search for location, but set cursor with context menu
        this.searched = false;
      }
      if (resp) {
        this.selectedMode = resp;
        this.cdr.detectChanges();
      }
      if (resp == "rha") {
        this.failedPlan = false;
      }
    }));

    this.subscriptions.push(this.inputParamsService.ObservableWaypointsParams.subscribe((resp) => {
      if (resp && resp.length == 1) {
        this.routeList = [this.routeList[0]];
        this.inputParamsService.setWaypointsRouteList(this.routeList);
        this.noRoute = true;
        this.inputParamsService.ObservableNoRoute.next(true);
      }

      this.checkEmptySearchInput();
    }));

    // observe mobile resolution
    this.subscriptions.push(this.mobileResolutionService.ObservableMobileVisiblePanel.subscribe((resp) => {
      this.mobileVisiblePanel = resp;
      this.infoScrollbar.update();
      this.cdr.detectChanges();
    }));

    this.subscriptions.push(this.mobileResolutionService.ObservableMobileVisibleRoutePanel.subscribe((resp) => {
      if (resp) {
        this.mobileVisibleRoutePanel = resp;
        this.cdr.detectChanges();
      }
    }));

    this.subscriptions.push(this.mobileResolutionService.ObservableMobileResolution.subscribe((resp) => {
      this.mobileResolution = resp;
      this.infoScrollbar.update();
      this.cdr.detectChanges();
    }));

    // observe route planned
    this.subscriptions.push(this.mapService.ObservableLastRouteData.subscribe((resp) => {
      if (resp) {
        this.initNewPlannedRoute(resp);
      }
    }));

    this.subscriptions.push(this.navigationService.ObservableChangeNavigation.subscribe((resp) => {
      if (resp && resp == "start") {
        this.navigation = true;
        this.removeEmptySearchInput();
      }
      if (resp && resp == "exit") {
        this.navigation = false;
        this.inputParamsService.setWaypointsRouteList(this.routeList);
      }
      if (resp && resp == "arrive") {
        this.navigation = false;
        this.routeList = [this.routeList[0]];
        this.inputParamsService.setWaypointsRouteList(this.routeList);
      }
      this.checkEmptySearchInput();
      this.infoSidebarHeight = this.getInfoSidebarDefaultHeight();
      this.cdr.detectChanges();
    }));

    // highlight the next turn
    this.subscriptions.push(this.navigationService.ObservableNextTurn.subscribe((resp) => {
      if (resp && this.navigation) {

        this.itineraryNextTurnIdx = resp.NextTurnInstruction.Idx;
        this.roadLanesLength = resp.NextTurnInstruction.LaneInfo.length;
        this.cdr.detectChanges();

        const actHudComputers = (resp.DistToNextChargingStation != null || resp.DistToNextWaypoint != null) ? 2 : 1;
        if (this.lastHudComputers != actHudComputers) {
          setTimeout(() => {
            this.infoScrollbar.update();
          }, 300);
          this.lastHudComputers = actHudComputers;
        }
      }
    }));

    // a failed plan set navigaton inactive
    this.subscriptions.push(this.inputParamsService.ObservableFailedPlan.subscribe((resp) => {
      if (resp != undefined && resp != null) {
        this.failedPlan = resp.failedPlan;
        this.maxReachedWaypoint = resp.maxReachedWaypoint;
        this.cdr.detectChanges();
      }
    }));

    // a failed plan set navigaton inactive
    this.subscriptions.push(this.inputParamsService.ObservableNoRoute.subscribe((resp) => {
      if (resp != undefined && resp != null) {
        this.noRoute = resp;
        if (this.noRoute == true) {
          this.failedPlan = false;
        }
        this.cdr.detectChanges();
      }
    }));

    this.subscriptions.push(this.inputParamsService.ObservableAddEndPoint.subscribe((resp) => {
      if (resp != undefined && resp != null) {
        for (let i = 0; i < this.routeList.length; i++) {
          if (this.routeList[i].lat == 0 && this.routeList[i].lng == 0) {
            this.routeList.splice(i, 1);
            i--;
          }
        }
        this.routeList.push({
          display_name: resp.display_name,
          lat: resp.lat,
          lng: resp.lng,
          wp: this.routeList.length,
          csid: resp.csid,
          distance: 0
        });

        this.inputParamsService.setWaypointsRouteList(this.routeList);
        this.checkEmptySearchInput();
        this.cdr.detectChanges();
      }
    }));

    this.subscriptions.push(this.inputParamsService.ObservableAddRoutePoint.subscribe((resp) => {
      if (resp != undefined && resp != null) {
        for (let i = 0; i < this.routeList.length; i++) {
          if (this.routeList[i].lat == 0 && this.routeList[i].lng == 0) {
            this.routeList.splice(i, 1);
            i--;
          }
        }
        this.routeList.splice(resp.Index, 0, {
          display_name: resp.RouteListElement.display_name,
          lat: resp.RouteListElement.lat,
          lng: resp.RouteListElement.lng,
          wp: this.routeList.length,
          csid: resp.RouteListElement.csid,
          distance: 0
        });

        this.inputParamsService.setWaypointsRouteList(this.routeList);
        this.checkEmptySearchInput();
        this.cdr.detectChanges();
      }
    }));

    this.subscriptions.push(this.inputParamsService.ObservableSetStartPointGeocode.subscribe((resp) => {
      if (resp != undefined && resp != null) {
        this.routeList[0] = {
          display_name: resp.display_name,
          lat: resp.lat,
          lng: resp.lng,
          wp: resp.wp,
          csid: resp.csid,
          distance: 0
        };
        this.inputParamsService.setWaypointsRouteList(this.routeList);
        this.checkEmptySearchInput();
        this.cdr.detectChanges();
      }
    }));

    this.subscriptions.push(this.inputParamsService.ObservableWaypointsRouteListParams.subscribe((resp) => {
      if (resp != undefined) {
        this.routeList = resp;
        this.checkEmptySearchInput();
        this.cdr.detectChanges();
      }
    }));

    this.subscriptions.push(this.accountService.ObservableIsAuthorized.subscribe((resp) => {
      if (resp != undefined) {
        this.isAuthorized = resp;
        this.cdr.detectChanges();
      }
    }));

    this.subscriptions.push(this.mapService.ObservableActiveInfoSidebarRouteElement.subscribe((resp) => {
      if (resp != null && resp != undefined) {
        if (resp.type === 'start') {
          this.activeInfoSidebarRouteElement = 0;
        }
        else if (resp.type === 'clear') {
          this.activeInfoSidebarRouteElement = null;
        }
        else if (resp.type === 'end') {
          this.activeInfoSidebarRouteElement = this.routeData.RoutePlan.length - 1;
        }
        else if (resp.type === 'charger') {
          let routePlanIndex = 0;
          let chargerIndex = 0;
          while (chargerIndex <= resp.index && routePlanIndex < this.routeData.RoutePlan.length) {
            if (this.routeData.RoutePlan[routePlanIndex].Type === RoutePlanType.Charger) {
              chargerIndex++;
            }
            routePlanIndex++;
          }
          this.activeInfoSidebarRouteElement = routePlanIndex - 1;
        }
        else if (resp.type === 'waypoint') {
          let routePlanIndex = 0;
          let waypointIndex = 0;
          while (waypointIndex <= resp.index && routePlanIndex < this.routeData.RoutePlan.length) {
            if (this.routeData.RoutePlan[routePlanIndex].Type === RoutePlanType.WayPoint) {
              waypointIndex++;
            }
            routePlanIndex++;
          }
          this.activeInfoSidebarRouteElement = routePlanIndex - 1;
        }
        this.cdr.detectChanges();
      }
    }));
  }

  testGpsCount: number = 0;
  testGpsEl: Position = new Position(0, 0, 0, 0, 0, 0, 0, 0);

  // changing searchbox text, after context menu action
  ngAfterViewInit(): void {
    this.subscriptions.push(this.inputParamsService.ObservableReverseGeocodedLocations.subscribe((resp) => {
      if (resp) {
        this.routeList = [];
        for (let i = 0; i < resp.length; i++) {
          this.routeList[i] = {
            display_name: this.mapService.parseAddressString(resp[i], resp[i].Location.lat, resp[i].Location.lng),
            lat: resp[i].Location.lat, lng: resp[i].Location.lng, wp: i, distance: 0, csid: resp[i].Location.csid
          };
        }
        this.inputParamsService.setWaypointsRouteList(this.routeList);
        this.checkEmptySearchInput();
        this.infoScrollbar.update();
        this.cdr.detectChanges();
      }
    }));

    this.initPaneEvents();
  }

  private initPaneEvents(): void {
    // --------------- Pane click events ---------------
    const pDown = fromEvent(this.paneDragOuter.nativeElement, 'mousedown');
    const pMove = fromEvent(document, 'mousemove');
    const pUp = fromEvent(document, 'mouseup')
    const pDrag = pDown.pipe(concatMap(DragEvent => pMove.pipe(takeUntil(pUp))));
    let lastStartCoord = 0;

    pDrag.subscribe((md: MouseEvent) => {
      this.infoSidebarHeight = md.pageY - 55;
      this.checkInfoSidebarLimits();
      this.infoSidebarSetMobileHeight = this.infoSidebarHeight;
      this.cdr.detectChanges();
    });
    pUp.subscribe((mu: MouseEvent) => {
      if (lastStartCoord) {
        if (mu.pageY > lastStartCoord) {
          this.infoSidebarHeight = window.innerHeight / 5 * 3;
        }
        else {
          this.infoSidebarHeight = 218;
        }
        lastStartCoord = 0;
        this.cdr.detectChanges();
      }
    });

    // --------------- Pane touch events ---------------
    const pTouchStart = fromEvent(this.paneDragOuter.nativeElement, 'touchstart');
    const pTouchMove = fromEvent(this.paneDragOuter.nativeElement, 'touchmove');
    const pTouchEnd = fromEvent(this.paneDragOuter.nativeElement, 'touchend');
    const pTouchDrag = pTouchStart.pipe(concatMap(DragEvent => pTouchMove.pipe(takeUntil(pTouchEnd))));

    pTouchDrag.subscribe((td: TouchEvent) => {
      this.infoSidebarHeight = td.changedTouches[0].pageY - 55;
      this.checkInfoSidebarLimits();
      this.infoSidebarSetMobileHeight = this.infoSidebarHeight;
      this.cdr.detectChanges();
    });
    pTouchStart.subscribe((td: TouchEvent) => {
      lastStartCoord = td.changedTouches[0].pageY;
    });
    pTouchEnd.subscribe((te: TouchEvent) => {
      if (lastStartCoord) {
        if (te.changedTouches[0].pageY > lastStartCoord) {
          this.infoSidebarHeight = window.innerHeight / 5 * 3;
        }
        else {
          this.infoSidebarHeight = 218;
        }
        lastStartCoord = 0;
        this.cdr.detectChanges();
      }
    });
  }

  public get RoutePlanType(): typeof RoutePlanType {
    return RoutePlanType;
  }

  public getInfoSidebarDefaultHeight(): number {
    if (window.innerWidth <= 1050 || window.innerHeight <= 700) {
      return this.infoSidebarSetMobileHeight;
    }
    else {
      if (this.navigation) {
        return window.innerHeight - 69;
      }
      else {
        return window.innerHeight - 55;
      }
    }
  }

  private checkInfoSidebarLimits(): void {
    if (this.infoSidebarHeight < 218) {
      this.infoSidebarHeight = 218;
    }
    if (this.infoSidebarHeight > window.innerHeight / 5 * 3) {
      this.infoSidebarHeight = window.innerHeight / 5 * 3;
    }
  }

  private initNewPlannedRoute(routeData: Route): void {
    this.searched = false;

    // route basic data
    this.routeData = routeData;
    this.routeData.Turns = this.mapService.initTurnIconAndInstruction(this.routeData.Turns);
    this.calculateRoutePlanpointDistances();

    this.noRoute = false;
    this.inputParamsService.ObservableNoRoute.next(false);

    // position map after route loaded on the mobile devices
    setTimeout(() => {
      this.mapService.ObservablePositionMapToRoute.next(this.inputParamsService.getWaypointsParams().map((el) => {
        return new LatLng(el.lat, el.lng)
      }));
    }, 100);

    this.cdr.detectChanges();
  }

  /** Calculating distances between two points  */
  private calculateRoutePlanpointDistances(): void {
    this.routeData.RoutePlan.filter((el) => { return el.Type != RoutePlanType.Charger }).forEach((routePlanElement, i) => {
      if (this.routeList.length > i) {
        this.routeList[i].distance = routePlanElement.DistanceFromStart;
      }
    });
  }

  public itineraryToggle(): void {
    this.itineraryDropdown = !this.itineraryDropdown;
    this.cdr.detectChanges();
    this.infoScrollbar.update();
  }

  public getChargeTime(chargeTimeHour, chargeTimeMin): string {
    return this.utilsService.getChargeTime(chargeTimeHour, chargeTimeMin);
  }

  // set start coord after search
  public setStartCoordsParamToSearchedResult(): void {
    this.searched = false;
    const searchData = this.mapService.getSearchedLocation();
    this.routeList[0] = {
      display_name: searchData.display_name,
      lat: searchData.lat,
      lng: searchData.lon
    };
    this.routeList[0].wp = 0;
    const latlon = new L.LatLng(searchData.lat, searchData.lon);
    this.inputParamsService.setSearchedRangeOrRoute(true);
    this.inputParamsService.setStartCoordsParams(latlon);
    this.inputParamsService.setWaypointsRouteList(this.routeList);
    this.checkEmptySearchInput();
    this.rhaSearchValue = "";
    this.cdr.detectChanges();
  }

  // set end coord after search
  public setEndCoordsParamToSearchedResult(): void {
    this.searched = false;
    const searchData = this.mapService.getSearchedLocation();
    this.routeList[1] = {
      display_name: searchData.display_name,
      lat: searchData.lat,
      lng: searchData.lon
    };
    this.routeList[1].wp = 1;
    const latlon = new L.LatLng(searchData.lat, searchData.lon);
    this.inputParamsService.setSearchedRangeOrRoute(true);
    const waypoints = [...this.inputParamsService.getWaypointsParams()];
    waypoints.push(latlon);
    this.inputParamsService.setWaypointParams(waypoints);
    this.inputParamsService.setWaypointsRouteList(this.routeList);
    this.checkEmptySearchInput();
    this.rhaSearchValue = "";
    this.cdr.detectChanges();
  }

  public addRoutePoint(): void {
    if (!this.emptySearchInput && !this.failedPlan) {
      this.routeList.push({
        display_name: "",
        lat: 0,
        lng: 0,
        wp: -1,
        distance: 0
      });
      this.infoScrollbar.update();
      this.inputParamsService.setWaypointsRouteList(this.routeList);
      this.checkEmptySearchInput();
      this.cdr.detectChanges();
    }
  }

  public setSearchedToHome(): void {
    if (!this.isAuthorized) {
      this.openAccount();
    }
    else {
      let homeAddress = this.settingsService.getHomeAddress();
      if (homeAddress?.Description) {
        let homeElement = {
          display_name: homeAddress.Description,
          lat: homeAddress.Location.lat,
          lon: homeAddress.Location.lng,
          boundingbox: homeAddress.BoundingBox
        }
        this.searchChangedHandler(homeElement);
        this.rhaSearchValue = homeElement.display_name;
        this.cdr.detectChanges();
      }
      else {
        this.openSettings();
      }
    }
  }

  public setSearchedToWork(): void {
    if (!this.isAuthorized) {
      this.openAccount();
    }
    else {
      let workAddress = this.settingsService.getWorkAddress();
      if (workAddress?.Description) {
        let workElement = {
          display_name: workAddress.Description,
          lat: workAddress.Location.lat,
          lon: workAddress.Location.lng,
          boundingbox: workAddress.BoundingBox
        }
        this.searchChangedHandler(workElement);
        this.rhaSearchValue = workElement.display_name;
        this.cdr.detectChanges();
      }
      else {
        this.openSettings();
      }
    }
  }

  public addHomeToRoutePoint(): void {
    if (!this.isAuthorized) {
      this.openAccount();
    }
    else if (!this.failedPlan) {
      let homeAddress = this.settingsService.getHomeAddress();
      if (homeAddress?.Description) {
        this.inputParamsService.addEndpoint({
          display_name: homeAddress.Description,
          lat: homeAddress.Location.lat,
          lng: homeAddress.Location.lng,
        });
      }
      else {
        this.openSettings();
      }
    }
  }

  public addWorkToRoutePoint(): void {
    if (!this.isAuthorized) {
      this.openAccount();
    }
    else if (!this.failedPlan) {
      let workAddress = this.settingsService.getWorkAddress();
      if (workAddress?.Description) {
        this.inputParamsService.addEndpoint({
          display_name: workAddress.Description,
          lat: workAddress.Location.lat,
          lng: workAddress.Location.lng,
        });
      }
      else {
        this.openSettings();
      }
    }
  }

  public isDragAndDrop(): boolean {
    if (this.routeList[this.routeList.length - 1].wp > 0) {
      return true;
    }

    if (this.routeList.length > 1 && this.routeList[this.routeList.length - 2].wp > 0) {
      return true;
    }

    return false;
  }

  // drag and drop, drop event
  public drop(event): void {
    if (event.previousIndex != event.currentIndex) {
      if (this.mobileResolutionService.getMobileVisiblePanel() == 'search') {
        this.mobileResolutionService.setMobileVisiblePanel('');
      }
      moveItemInArray(this.routeList, event.previousIndex, event.currentIndex);

      this.inputParamsService.setSearchedRangeOrRoute(true);
      const newWaypoints: Waypoint[] = [];
      for (let i = 0; i < this.routeList.length; i++) {
        if (this.routeList[i].lat != 0 && this.routeList[i].lng != 0) {
          newWaypoints.push(new Waypoint(this.routeList[i].lat, this.routeList[i].lng, this.routeList[i].csid));
          this.routeList[i].wp = newWaypoints.length - 1;
        } else {
          this.routeList[i].wp = -1;
        }
      }

      this.inputParamsService.setWaypointParams(newWaypoints);
      this.inputParamsService.setWaypointsRouteList(this.routeList);
      this.checkEmptySearchInput();
    }
  }

  // remove a searched point
  public deletePoint(i: number): void {
    if (this.mobileResolutionService.getMobileVisiblePanel() == 'search') {
      this.mobileResolutionService.setMobileVisiblePanel('');
    }

    const wp = this.routeList[i].wp;
    this.routeList.splice(i, 1);

    this.infoScrollbar.update();
    this.inputParamsService.setSearchedRangeOrRoute(true);
    if (wp >= 0) {
      this.inputParamsService.deleteWaypoint(wp);
      for (let i = 0; i < this.routeList.length; i++) {
        if (this.routeList[i].wp > wp) {
          this.routeList[i].wp -= 1;
        }
      }
    }
    this.inputParamsService.setWaypointsRouteList(this.routeList);
    this.checkEmptySearchInput();
  }

  public setStartCoordsToYourGeolocation(): void {

    const unshiftWaypoints = (this.routeList[0].lat == 0 && this.routeList[0].lat == 0);
    this.inputParamsService.setStartCoordsToYourGeolocation(unshiftWaypoints).subscribe((resp) => {
      if (resp == "nogps") {
        this._ngZone.run(() => { this.openGpsErrorDialog(); })

      }
      else {
        const startcoord = this.inputParamsService.getStartCoordsParams();
        this.mapService.getReverseGeocode(startcoord.lat, startcoord.lng).subscribe((reversegeocode) => {
          if (reversegeocode) {
            this.inputParamsService.ObservableSetStartPointGeocode.next({
              display_name: reversegeocode,
              lat: startcoord.lat,
              lng: startcoord.lng,
              wp: 0
            });
            if (unshiftWaypoints) {
              for (let i = 0; i < this.routeList.length; i++) {
                this.routeList[i].wp = i;
              }
            }
            this.inputParamsService.setWaypointsRouteList(this.routeList);
            this.checkEmptySearchInput();
          }
        }, () => { });
      }
    });
  }

  public stopTrackingDialog(): MatDialogRef<ConfirmationDialogComponent> {
    return this.MatDialog.open(ConfirmationDialogComponent, {
      data:
      {
        title: this.languageService.languageJSON.Map_StopTracking,
        message: this.languageService.languageJSON.Map_StopTracking_Message,
        buttons: [this.languageService.languageJSON.Global_Stop, this.languageService.languageJSON.Global_Cancel]
      }, autoFocus: false
    });
  }

  // adding searched location to service
  public searchChangedHandler(searchData: SearchedRouteElement): void {
    if (this.mobileResolutionService.getMobileVisiblePanel() == 'search') {
      this.mobileResolutionService.setMobileVisiblePanel('');
    }

    this.mapService.setSearchedLocation(searchData);
    this.searched = true;
    this.cdr.detectChanges();
  }

  public keyPressedHandler(): void {
    this.infoScrollbar.update();
    this.cdr.detectChanges();
  }

  // handle search waypoint changed event
  public waypointChangedHandler(searchData: SearchedRouteElement, i): void {
    if (this.mobileResolutionService.getMobileVisiblePanel() == 'search') {
      this.mobileResolutionService.setMobileVisiblePanel('');
    }

    this.routeList[i] = {
      display_name: searchData.display_name,
      lat: searchData.lat,
      lng: searchData.lon
    };
    this.cdr.detectChanges();
    this.inputParamsService.setSearchedRangeOrRoute(true);
    const newWaypoints = [];
    for (let ix = 0; ix < this.routeList.length; ix++) {
      if (this.routeList[ix].lat != 0 && this.routeList[ix].lng != 0) {
        newWaypoints.push(new Waypoint(this.routeList[ix].lat, this.routeList[ix].lng));
        this.routeList[ix].wp = newWaypoints.length - 1;
      }
      else {
        this.routeList[ix].wp = -1;
      }
    }
    this.inputParamsService.setWaypointParams(newWaypoints);
    this.inputParamsService.setWaypointsRouteList(this.routeList);
    this.checkEmptySearchInput();
    this.infoScrollbar.update();
  }

  private checkEmptySearchInput(): void {
    let emptyCount = 0;
    for (let i = 0; i < this.routeList.length; i++) {
      if (this.routeList[i].lat == 0 && this.routeList[i].lng == 0) {
        emptyCount++;
      }
    }

    if (emptyCount == 0) {
      this.emptySearchInput = false;
    }
    else {
      this.emptySearchInput = true;
    }
  }

  private removeEmptySearchInput(): void {
    this.routeList = this.routeList.filter((el) => { return el.lat != 0 && el.lng != 0 });
  }

  public gpxNavigation(event: Event): void {
    const file = (event.target as HTMLInputElement).files[0];
    if (!file) {
      return;
    }
    const reader = new FileReader();
    reader.onload = (e: any) => {
      const contents = e.target.result;
      const parser = new DOMParser();
      const xmlDoc = parser.parseFromString(contents, "application/xml");
      this.navigationService.selectedReplayTrack = xmlDoc;
      this.navigationService.ObservableChangeNavigation.next("start");
      this.locationService.setFakeGPSReplayMode(true);
      this.navigation = true;
      this.cdr.detectChanges();
    };
    reader.readAsText(file);
  }

  public openBatteryReservedSnackbar(): void {
    this.snackBar.open(this.languageService.languageJSON.WarningSoc_popup, "", { duration: 5000, panelClass: 'snackbar-warning' });
  }

  public navigateToTurn(turn: Turn): void {
    if (!this.navigation) {
      this.mapService.setSelectedCoordinate(this.routeData.RoutePoints[turn.RoutePointIdxStart].Location);
    }
  }

  // navigate to location
  public openRoutePlanPoint(routeEl: RoutePlanElement, index?: number): void {
    if (!this.navigation) {
      this.mapService.setSelectedCoordinate(routeEl.Location);
      if (index != undefined) {
        this.activeInfoSidebarRouteElement = index;
      }

      if (routeEl.Type == RoutePlanType.Charger) {
        this.mapService.getChargingStationDetail([routeEl.ChargingStationId]).subscribe((resp) => {
          this.mapService.ObservableClearLastOpenedChargingStation.next(true);
          this.mapService.setSearchedMapElement(MapElementType.Chargingstation, resp[0]);
        });
      }
    }
  }

  public getRouteStartIcon(routeElement: RouteListElement): string {
    return routeElement.wp == -1 ? 'empty' : 'assets/icons/dark/ui/start_icon.svg';
  }

  public getRouteWaypointIcon(routeElement: RouteListElement): string {
    if (routeElement.wp == -1) {
      return "empty";
    }
    else if (routeElement.csid) {
      return "assets/icons/default/ui/charge_here_waypoint_list.svg";
    }
    else {
      return "waypoint";
    }
  }

  public getRouteEndpointIcon(routeElement: RouteListElement): string {
    if (routeElement.wp == -1) {
      return "empty";
    }
    else if (routeElement.csid) {
      return "assets/icons/default/ui/charge_here_target_list.svg";
    }
    else {
      return "assets/icons/dark/ui/target_icon.svg";
    }
  }

  public showMetricMeter(m: number): boolean {
    if (m < 1000 && this.settingsService.getUnit().Distance == Unit.Metric) {
      return true;
    }
    else {
      return false;
    }
  }

  public showMetricKMeter(m: number): boolean {
    if (m >= 1000 && this.settingsService.getUnit().Distance == Unit.Metric) {
      return true;
    }
    else {
      return false;
    }
  }

  public showImperialYard(m: number): boolean {
    if (this.utilsService.mToYard(m) < 1760 && this.settingsService.getUnit().Distance == Unit.Imperial) {
      return true;
    }
    else {
      return false;
    }
  }

  public showImperialMile(m: number): boolean {
    if (this.utilsService.mToYard(m) >= 1760 && this.settingsService.getUnit().Distance == Unit.Imperial) {
      return true;
    }
    else {
      return false;
    }
  }

  // open error dialog modal
  public openGpsErrorDialog(): void {
    if (!this.webviewService.IsGPSTWebview()) {
      this._ngZone.run(() => { let dialogRef = this.MatDialog.open(GpsErrorDialogComponent); });
    }
  }

  // change unit
  public openSettings(): void {
    this._ngZone.run(() => {
      let dialogRef = this.MatDialog.open(SettingsDialogComponent,
        { panelClass: 'dialog-snap-to-fullscreen', disableClose: true, autoFocus: false })
    });
  }

  public openAccount(): void {
    this._ngZone.run(() => {
      let dialogRef = this.MatDialog.open(AccountDialogComponent, {
        data: { process: 'signIn' },
        panelClass: 'dialog-snap-to-fullscreen'
      })
    });
  }

  public isFastchargeIcon(maxPercent): boolean {
    return this.inputParamsService.getFastChargeLimit() < maxPercent && this.mapService.getSelectedCar().FastChargePower != 0;
  }

  public openFastchargeDialog(event): void {
    event.stopPropagation();

    this._ngZone.run(() => {
      let dialogRef = this.MatDialog.open(InfoDialogComponent,
        { data: { message: this.languageService.languageJSON.InfoSidebar_Info_FastChargeLimit }, autoFocus: false });
    });
  }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    for (let i = 0; i < this.subscriptions.length; i++) {
      this.subscriptions[i].unsubscribe();
    }
    this.subscriptions = [];
  }
}
