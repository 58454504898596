import { Injectable } from '@angular/core';
import { BehaviorSubject, forkJoin } from 'rxjs';
import { AudioService } from './audio.service';
import { HttpClient } from '@angular/common/http';
import { ConsoleLoggerService } from './console-logger.service';
import { SettingsService, Unit } from './settings.service';
import { LanguageService } from './language.service';
import { VoiceCommandDesc } from '../models/voice-command-desc';
import { RecognizedVoiceCommand } from '../models/recognized-voice-command';
import { StackedModalsService } from './stacked-modals.service';
import { VoiceCommandErrorDialogComponent } from '../components/modals/voice-command-error-dialog/voice-command-error-dialog.component';
import { WebviewService } from './webview.service';
import device from 'current-device';

export interface AppWindow extends Window {
  webkitSpeechRecognition: any;
  SpeechRecognition: any;
}

const { webkitSpeechRecognition }: AppWindow = (window as any) as AppWindow;
const { SpeechRecognition }: AppWindow = (window as any) as AppWindow;

export const enum VoiceCommand {
  Invalid = -1,
  Confirmation_No = 0,
  Confirmation_Yes = 1,
  SetBattery = 2,
  SkipWP = 3,
  SkipCS = 4,
  StopNavigation = 5,
  SetWeight = 6,
  NavigateToHome = 7,
  NavigateToWork = 8,
  ReadEta = 9,
  ReadEte = 10,
  ReadRemainingDist = 11
}

export const enum VoiceCommandValueType {
  Integer,
  Boolean,
}

const CONFIDENCE_THRESHOLD: number = 0.5;
const DEFAULT_STOP_RECOGNITION_TIMEOUT_MS: number = 5000;
const DEFAULT_STOP_RECOGNITION_WAIT_COUNT: number = 2;

@Injectable({
  providedIn: 'root'
})

export class SpeechService {

  private SupportVoiceRecognition: boolean;
  private SpeechRecognition: any;
  private VoiceCommandCatched: boolean;
  private CommandToConfirm: VoiceCommand = VoiceCommand.Invalid;
  private ValueToConfirm: any = null;
  private ActiveCommands: VoiceCommand[] = [];
  private StopRecognitionTimeOutMs: number = DEFAULT_STOP_RECOGNITION_TIMEOUT_MS;
  private StopRecognitionWaitCount: number = DEFAULT_STOP_RECOGNITION_WAIT_COUNT;
  private voiceRecognitionLang = "en";

  private VoiceCommandDescs: VoiceCommandDesc[] = [
    new VoiceCommandDesc(["no", "not"], VoiceCommandValueType.Boolean, 0, 0, null),
    new VoiceCommandDesc(["yes", "ok", "okay"], VoiceCommandValueType.Boolean, 0, 0, null),
    new VoiceCommandDesc(["battery", "better"], VoiceCommandValueType.Integer, 0, 100, (param: any) => { return this.GetBatteryConfirmedSounds(param) }),
    new VoiceCommandDesc(["skip waypoint", "skip Waypoint", "keep waypoint"], VoiceCommandValueType.Boolean, 0, 0, (param: any) => { return this.GetSkipWaypointConfirmedSound() }),
    new VoiceCommandDesc(["skip chargingstation", "skip charging station", "keep chargin station", "keep chargingstation"], VoiceCommandValueType.Boolean, 0, 0, (param: any) => { return this.GetSkipChargingStationConfirmedSound() }),
    new VoiceCommandDesc(["stop navigation", "exit navigation", "close navigation", "quit navigation"], VoiceCommandValueType.Boolean, 0, 0, (param: any) => { return this.GetStopNavigationConfirmedSound() }),
    new VoiceCommandDesc(["number of persons", "number of person", "number of passengers", "number of passenger", "number of people", "number of peoples"], VoiceCommandValueType.Integer, 1, 5, (param: any) => { return this.GetSetWeightConfirmedSounds(param) }),
    new VoiceCommandDesc(["navigate to home", "navigate home"], VoiceCommandValueType.Boolean, 0, 0, (param: any) => { return this.GetNavigationToHomeConfirmedSounds() }),
    new VoiceCommandDesc(["navigate to work", "navigate work"], VoiceCommandValueType.Boolean, 0, 0, (param: any) => { return this.GetNavigationToWorkConfirmedSounds() }),
    new VoiceCommandDesc([/*"eta", "estimated time of arrival", "estimate time arrival", "estimated time"*/], VoiceCommandValueType.Boolean, 0, 0, (param: any) => { return this.GetEtaConfirmedSounds(param) }),
    new VoiceCommandDesc(["ete", "yty", "estimated time enroute", "estimated time and route", "estimated time in route", "estimated time on route", "estimated time I route", "estimated time iroot", "estimated time root",
      "time to destination", "estimated time to destination", "time destination", "destination time", "time remaining"], VoiceCommandValueType.Boolean, 0, 0, (param: any) => { return this.GetEteConfirmedSounds(param) }),
    new VoiceCommandDesc(["remaining distance", "distance to destination", "distance destination", "destination distance"], VoiceCommandValueType.Boolean, 0, 0, (param: any) => { return this.GetRemainingDistanceSounds(param) })
  ];

  /* todo
  private VoiceCommandDescs: VoiceCommandDesc[] = [
    new VoiceCommandDesc([this.languageService.languageJSON.Global_No, this.languageService.languageJSON.Global_Not], VoiceCommandValueType.Boolean, 0, 0, null),
    new VoiceCommandDesc([this.languageService.languageJSON.Global_Yes, this.languageService.languageJSON.Global_Ok, `okay`], VoiceCommandValueType.Boolean, 0, 0, null),
    new VoiceCommandDesc([this.languageService.languageJSON.SpeechService_Battery, `better`], VoiceCommandValueType.Integer, 0, 100, (param: any) => { return this.GetBatteryConfirmedSounds(param) }),
    new VoiceCommandDesc([this.languageService.languageJSON.NavigationHud_SkipWaypoint, `keep waypoint`], VoiceCommandValueType.Boolean, 0, 0, (param: any) => { return this.GetSkipWaypointConfirmedSound() }),
    new VoiceCommandDesc([this.languageService.languageJSON.NavigationHud_SkipChargingStation, `skip chargingstation`, `skip charging station`, `keep chargin station`, `keep chargingstation`], VoiceCommandValueType.Boolean, 0, 0, (param: any) => { return this.GetSkipChargingStationConfirmedSound() }),
    new VoiceCommandDesc([this.languageService.languageJSON.NavigationHud_StopNavigation, `exit navigation`, `close navigation`, `quit navigation`], VoiceCommandValueType.Boolean, 0, 0, (param: any) => { return this.GetStopNavigationConfirmedSound() }),
    new VoiceCommandDesc([this.languageService.languageJSON.SpeechService_NumberOfPassangers, `number of persons`, `number of person`, `number of passenger`, `number of people`, `number of peoples`], VoiceCommandValueType.Integer, 1, 5, (param: any) => { return this.GetSetWeightConfirmedSounds(param) }),
    new VoiceCommandDesc([this.languageService.languageJSON.SpeechService_NavigateToHome, `navigate home`], VoiceCommandValueType.Boolean, 0, 0, (param: any) => { return this.GetNavigationToHomeConfirmedSounds() }),
    new VoiceCommandDesc([this.languageService.languageJSON.SpeechService_NavigateToWork, `navigate work`], VoiceCommandValueType.Boolean, 0, 0, (param: any) => { return this.GetNavigationToWorkConfirmedSounds() }),
    new VoiceCommandDesc([/*`eta`, `estimated time of arrival`, `estimate time arrival`, `estimated time`*/ /*], VoiceCommandValueType.Boolean, 0, 0, (param: any) => { return this.GetEtaConfirmedSounds(param) }),
new VoiceCommandDesc([`ete`, `yty`, `estimated time enroute`, `estimated time and route`, `estimated time in route`, `estimated time on route`, `estimated time I route`, `estimated time iroot`, `estimated time root`,
this.languageService.languageJSON.SpeechService_TimeToDestination, `estimated time to destination`, `time destination`, `destination time`, `time remaining`], VoiceCommandValueType.Boolean, 0, 0, (param: any) => { return this.GetEteConfirmedSounds(param) }),
new VoiceCommandDesc([this.languageService.languageJSON.SpeechService_RemainingDistance, `distance to destination`, `distance destination`, `destination distance`], VoiceCommandValueType.Boolean, 0, 0, (param: any) => { return this.GetRemainingDistanceSounds(param) })
];*/

  public ObservableRecognizedVoiceCommand: BehaviorSubject<RecognizedVoiceCommand> = new BehaviorSubject<RecognizedVoiceCommand>(null);
  public ObservableTestRecognizedVoiceCommand: BehaviorSubject<{ sentences: string, confidence: number }> = new BehaviorSubject<{ sentences: string, confidence: number }>(null);
  public ObservableActiveVoiceCommands: BehaviorSubject<VoiceCommand[]> = new BehaviorSubject<VoiceCommand[]>(null);
  public ObservableOpenVoiceCommandListen: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
  public ObservableVoiceInputError: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  private RecognitionTimeOutRef: ReturnType<typeof setTimeout>;
  private RecognitionTimeOutCount: number = 0;
  private SpeechRecognized: boolean = false;

  //the onend event of the SpeechRecognition is missleading on phones
  private OnSpeechRecognitionStopped: (() => void)[] = [];
  private Audio: HTMLAudioElement = new Audio();

  constructor(private audioService: AudioService, private http: HttpClient,
    private consoleLoggerService: ConsoleLoggerService, private settingsService: SettingsService,
    private languageService: LanguageService, private stackedModalsService: StackedModalsService,
    private webviewService: WebviewService) {
    this.init();
  }

  private init(): void {
    this.SupportVoiceRecognition = true;

    if (!this.UsePrefixedSpeechInterface()) {
      this.SpeechRecognition = new SpeechRecognition();
    }
    else if (window['webkitSpeechRecognition']) {
      this.SpeechRecognition = new webkitSpeechRecognition();
    } else {
      this.SupportVoiceRecognition = false;
    }

    if (this.SupportVoiceRecognition) {
      this.SpeechRecognition.continuous = true;
      this.SpeechRecognition.lang = "en-US";
      /*this.SpeechRecognition.lang = this.settingsService.getLanguage();
      this.settingsService.ObservableLanguage.subscribe((resp) => {
        if (resp) {
          this.SpeechRecognition.lang = resp;
        }
      });*/

      this.SpeechRecognition.interimResults = true;

      this.SpeechRecognition.onresult = (event: any) => { this.OnRecognitionResult(event) };
      this.SpeechRecognition.onerror = (event: any) => { this.OnError(event) };
      this.SpeechRecognition.onaudiostart = (event: any) => {
        this.ObservableActiveVoiceCommands.next(this.ActiveCommands);
        if (this.ActiveCommands.indexOf(VoiceCommand.Confirmation_Yes) == -1) {
          this.ObservableOpenVoiceCommandListen.next(true);
        }
        this.SetStopRecognitionTimer();
      }

      this.SpeechRecognition.onend = (event) => {
      };

      this.SpeechRecognition.onaudioend = (event) => {
      };
    }

    this.webviewService.addOnSpeachRecognitionErrorCallback(() => {
      this.openVoiceInputErrorDialog();
    });

    this.webviewService.addMicrophoneInteractionCallback((permission: boolean) => {
      if (permission) {
        this.StartRecognition(
          [VoiceCommand.SetBattery, VoiceCommand.SkipWP, VoiceCommand.SkipCS,
          VoiceCommand.StopNavigation, VoiceCommand.SetWeight, VoiceCommand.NavigateToHome,
          VoiceCommand.NavigateToWork, VoiceCommand.ReadEta, VoiceCommand.ReadEte, VoiceCommand.ReadRemainingDist]
        );
      }
      else {
        this.openVoiceInputErrorDialog();
      }
    });
  }

  public VoiceRecognitionAvailable(): boolean {
    return true;
  }

  private OnRecognitionResult(event: any): void {
    if (!this.VoiceCommandCatched) {

      this.SpeechRecognized = true;
      const lastIdxResultList: number = event.results.length - 1;
      if (lastIdxResultList >= 0) {
        const lastIdxResult: number = event.results[lastIdxResultList].length - 1;
        if (lastIdxResult >= 0) {
          let sentences: string = event.results[lastIdxResultList][lastIdxResult].transcript.toLowerCase();
          let confidence: number = event.results[lastIdxResultList][lastIdxResult].confidence;
          this.consoleLoggerService.log(sentences, confidence);

          this.ObservableTestRecognizedVoiceCommand.next({ sentences: sentences.toString(), confidence: confidence });
          if (confidence > CONFIDENCE_THRESHOLD) {
            this.CheckVoiceCommands(sentences);
          }
        }
      }
    }
  }

  private GetBatteryConfirmedSounds(value: number): string[] {
    let ret: string[] = [`assets/audio/voice_recognition/${this.voiceRecognitionLang}/Battery_set_to.mp3`];
    ret = ret.concat(this.GetNumberSoundFiles(value));
    ret.push(`assets/audio/voice_recognition/${this.voiceRecognitionLang}/percent.mp3`);
    return ret;
  }

  private GetSkipWaypointConfirmedSound(): string[] {
    let ret = [];
    ret.push(`assets/audio/voice_recognition/${this.voiceRecognitionLang}/Waypoint_skipped.mp3`);
    return ret;
  }

  private GetSkipChargingStationConfirmedSound(): string[] {
    let ret = [];
    ret.push(`assets/audio/voice_recognition/${this.voiceRecognitionLang}/Charging_station_skipped.mp3`);
    return ret;
  }

  private GetStopNavigationConfirmedSound(): string[] {
    let ret = [];
    ret.push(`assets/audio/voice_recognition/${this.voiceRecognitionLang}/Navigation_stopped.mp3`);
    return ret;
  }

  private GetSetWeightConfirmedSounds(value: number): string[] {
    let ret = [];
    ret.push(`assets/audio/voice_recognition/${this.voiceRecognitionLang}/Set_passengers_to.mp3`);
    ret = ret.concat(this.GetNumberSoundFiles(value));
    return ret;
  }

  private GetNavigationToHomeConfirmedSounds(): string[] {
    let ret = [];
    ret.push(`assets/audio/voice_recognition/${this.voiceRecognitionLang}/Navigating_to_home.mp3`);
    return ret;
  }

  private GetNavigationToWorkConfirmedSounds(): string[] {
    let ret = [];
    ret.push(`assets/audio/voice_recognition/${this.voiceRecognitionLang}/Navigating_to_work.mp3`);
    return ret;
  }

  private GetEtaConfirmedSounds(time: number): string[] {
    let ret = [];
    ret.push(`assets/audio/voice_recognition/${this.voiceRecognitionLang}/Eta_is.mp3`);

    ret = ret.concat(this.GetNumberSoundFiles(Math.floor(time / 60)));
    time = time % 60;
    ret = ret.concat(this.GetNumberSoundFiles(time));

    return ret;
  }

  private GetEteConfirmedSounds(time: number): string[] {
    let ret = [];
    ret.push(`assets/audio/voice_recognition/${this.voiceRecognitionLang}/Ete_is.mp3`);
    if (time > 60) {
      ret = ret.concat(this.GetNumberSoundFiles(Math.floor(time / 60)));
      if (time / 60 >= 2) {
        ret.push(`assets/audio/voice_recognition/${this.voiceRecognitionLang}/Hours.mp3`);
      }
      else {
        ret.push(`assets/audio/voice_recognition/${this.voiceRecognitionLang}/Hour.mp3`);
      }
      time = time % 60;
    }
    ret = ret.concat(this.GetNumberSoundFiles(time));
    if (time >= 2) {
      ret.push(`assets/audio/voice_recognition/${this.voiceRecognitionLang}/Minutes.mp3`);
    }
    else {
      ret.push(`assets/audio/voice_recognition/${this.voiceRecognitionLang}/Minute.mp3`);
    }
    return ret;
  }


  private GetRemainingDistanceSounds(dist: number): string[] {
    let ret = [];
    ret.push(`assets/audio/voice_recognition/${this.voiceRecognitionLang}/RemainingDistance_is.mp3`);
    dist = this.audioService.RoundDistance(dist, this.settingsService.getUnit());
    if (dist != 0) {
      ret = ret.concat(this.audioService.getSoundFiles(dist, this.settingsService.getUnit(), `meter`));
      if (dist < 1000 && this.settingsService.getUnit().Distance == Unit.Metric) {
        ret.push(this.audioService.getAudioSource() + "meters.mp3");
      }

      if (dist < 1760 && this.settingsService.getUnit().Distance == Unit.Imperial) {
        ret.push(this.audioService.getAudioSource() + "yards.mp3");
      }
    }
    if (dist >= 1000 && dist < 2000 && this.settingsService.getUnit().Distance == Unit.Metric) {
      ret.push(this.audioService.getAudioSource() + "kilometer.mp3");
    }
    if (dist >= 2000 && this.settingsService.getUnit().Distance == Unit.Metric) {
      ret.push(this.audioService.getAudioSource() + "kilometers.mp3");
    }
    if (dist >= 1760 && this.settingsService.getUnit().Distance == Unit.Imperial) {
      ret.push(this.audioService.getAudioSource() + "miles.mp3");
    }
    return ret;
  }

  public PlayErrorNotAvailable(): void {
    this.SendVoiceMessage([`assets/audio/voice_recognition/${this.voiceRecognitionLang}/Error_not_available.mp3`], () => { });
  }

  public PlayErrorAvailableDuringNavigation(): void {
    this.SendVoiceMessage([`assets/audio/voice_recognition/${this.voiceRecognitionLang}/Error_available_during_navigation.mp3`], () => { });
  }

  private SetStopRecognitionTimer(): void {
    this.RecognitionTimeOutRef = setTimeout(() => { this.StopRecognitionTimeOut(); }, this.StopRecognitionTimeOutMs);
  }

  private StopRecognitionTimeOut(): void {
    if (!this.VoiceCommandCatched) {
      if (this.RecognitionTimeOutCount < this.StopRecognitionWaitCount && this.SpeechRecognized) {
        this.SpeechRecognized = false;
        this.RecognitionTimeOutCount++;
        this.SpeechRecognition.stop();

        //the SendVoiceMessage function can not merge this two mp3 files but other combinations work correctly 
        this.SendVoiceMessage([`assets/audio/voice_recognition/voice_command_inactive.mp3`], () => {
          this.SendVoiceMessage([`assets/audio/voice_recognition/${this.voiceRecognitionLang}/Try_again.mp3`], () => {
            if (!this.VoiceCommandCatched) {
              this.SendVoiceMessage([`assets/audio/voice_recognition/voice_command_active.mp3`], () => {
                this.SpeechRecognition.start();
              });
            }
          });
        })
      } else {
        this.StopRecognition();
      }
    }
  }

  private CheckVoiceCommands(sentences: string): void {
    for (let commandType of this.ActiveCommands) {
      let commandDesc: VoiceCommandDesc = this.VoiceCommandDescs[commandType];

      for (let keyWord of commandDesc.KeyWords) {
        let commandValue: any = this.GetCommandValue(sentences, keyWord.toLowerCase(), commandDesc);
        if (commandValue != null) {
          this.VoiceCommandRecognized(new RecognizedVoiceCommand(commandType, commandValue))
          break;
        }
      }
    }
  }

  private ResetRecognitionSession(): void {
    this.RecognitionTimeOutCount = 0;
    this.SpeechRecognized = false;
    clearTimeout(this.RecognitionTimeOutRef);
  }

  private VoiceCommandRecognized(voiceCommand: RecognizedVoiceCommand): void {
    this.VoiceCommandCatched = true;

    let commandToConfirm = this.CommandToConfirm;
    let valueToConfirm = this.ValueToConfirm;

    this.OnSpeechRecognitionStopped.push(() => {
      this.ObservableRecognizedVoiceCommand.next(voiceCommand);
    });
    this.StopRecognition();
  }

  private SendVoiceMessage(message: string[], speechEndFunction: Function): void {
    const playlistHttpCalls = [];
    for (let i = 0; i < message.length; i++) {
      playlistHttpCalls.push(this.http.get(message[i], { responseType: 'arraybuffer' }));
    }
    forkJoin(playlistHttpCalls).subscribe((resp: any[]) => {
      const url = window.URL.createObjectURL(new Blob(resp, { type: `audio/mp3` }));
      this.Audio.src = url;
      this.Audio.load();
      this.Audio.onended = () => { speechEndFunction(); }
      this.Audio.play();
    });
  }

  private GetNumberCommandValue(sentences: string, searchStartIdx: number, lowerInterval: number, upperInterval: number): number {
    let valueStr: string = ``;
    let numberFound: boolean = false;

    for (let c: number = searchStartIdx; c < sentences.length; c++) {
      if (sentences[c] >= '0' && sentences[c] <= '9') {
        numberFound = true;
        valueStr += sentences[c];
      }
      else if (numberFound) {
        break;
      }
    }

    if (valueStr.length > 0) {
      let value: number = parseInt(valueStr);
      if (value >= lowerInterval && value <= upperInterval) {
        return value;
      }
    }

    return Number.MAX_VALUE;
  }

  private IsLetter(letter): boolean {
    return /^[a-z]+$/.test(letter);
  }

  private IsSingleWord(sentences: string, startIdx: number, endIdx: number): boolean {
    return (startIdx == 0 || !this.IsLetter(sentences[startIdx - 1])) && (endIdx >= sentences.length || !this.IsLetter(sentences[endIdx]))
  }

  private GetCommandValue(sentences: string, currentKeyWord: string, commandDesc: VoiceCommandDesc): number | boolean {
    const commandIdx = sentences.indexOf(currentKeyWord);
    if (commandIdx != -1) {
      const valueStartIdx = commandIdx + currentKeyWord.length;
      switch (commandDesc.ValueType) {
        case VoiceCommandValueType.Integer:
          let value = this.GetNumberCommandValue(sentences, valueStartIdx, commandDesc.LowerInterval, commandDesc.UpperInterval);
          if (value != Number.MAX_VALUE) {
            return value;
          }
          break;
        case VoiceCommandValueType.Boolean:
          if (this.IsSingleWord(sentences, commandIdx, valueStartIdx)) {
            return true;
          }
      }
    }
    return null;
  }

  private OnError(event: any): void {
    if ((event?.error && event?.error === "service-not-allowed"
      || event?.error && event?.error === "not-allowed")) {

      if (this.webviewService.IsGPSTWebview() && device.ios()) {
        this.webviewService.onSpeachRecognitionError();
      }
      else {
        this.openVoiceInputErrorDialog();
      }
    }

    this.ObservableVoiceInputError.next(event);
    this.StopRecognition();
  }

  private openVoiceInputErrorDialog() {
    this.stackedModalsService.openModal(VoiceCommandErrorDialogComponent, {
      data: {
        errorMsg: "This feature is not supported on this device.",
        notSupported: true
      },
      autoFocus: false
    });
  }

  private UsePrefixedSpeechInterface(): boolean {
    return typeof SpeechRecognition === `undefined`;
  }

  public StartVoiceConfirmation(commandToConfirm: VoiceCommand, valueToConfirm: any): void {
    this.CommandToConfirm = commandToConfirm;
    this.ValueToConfirm = valueToConfirm;
    if (this.VoiceCommandDescs[commandToConfirm].ConfirmedMessageFunct != null) {
      this.SendVoiceMessage(this.VoiceCommandDescs[commandToConfirm].ConfirmedMessageFunct(valueToConfirm), () => { });
    }
  }

  public StartRecognition(commands: VoiceCommand[], timeout: number = DEFAULT_STOP_RECOGNITION_TIMEOUT_MS, waitCount: number = DEFAULT_STOP_RECOGNITION_WAIT_COUNT): void {
    this.audioService.setSpeechActive(true);
    this.ResetRecognitionSession();
    this.ActiveCommands = commands;
    this.StopRecognitionTimeOutMs = timeout;
    this.StopRecognitionWaitCount = waitCount;
    this.VoiceCommandCatched = false;

    this.SendVoiceMessage([`assets/audio/voice_recognition/voice_command_active.mp3`], () => {
      this.SpeechRecognition.start();
      this.ObservableActiveVoiceCommands.next(this.ActiveCommands);
    });
  }

  public StopRecognition(): void {
    this.SendVoiceMessage([`assets/audio/voice_recognition/voice_command_inactive.mp3`], () => {
      this.audioService.setSpeechActive(false);
      this.ResetRecognitionSession();
      this.SpeechRecognition.stop();
      this.ActiveCommands = [];
      this.CommandToConfirm = VoiceCommand.Invalid;
      this.ValueToConfirm = null;
      this.ObservableActiveVoiceCommands.next(this.ActiveCommands);
      for (let onStop of this.OnSpeechRecognitionStopped) {
        onStop();
      }
      this.OnSpeechRecognitionStopped = [];
    });
  }

  private GetNumberSoundFiles(number): string[] {
    let audioPlayList: string[] = [];

    if (number.toString().includes(`.`)) {
      audioPlayList = this.GetNumberSoundFiles(Math.trunc(number));
      audioPlayList.push(this.audioService.getAudioSource() + `point.mp3`);
      audioPlayList = audioPlayList.concat(this.GetNumberSoundFiles(parseInt(number.toString().split(`.`)[1])));
    }
    // 1-19
    else if (number > 0 && number <= 19) {
      audioPlayList.push(this.audioService.getAudioSource() + `Numbers/` + number + `.mp3`);
    }
    // 100 200 ...
    else if (number % 100 == 0 && Math.trunc(number / 1000) == 0) {
      audioPlayList.push(this.audioService.getAudioSource() + `Numbers/` + number + `.mp3`);
    }
    // 10 20 ...
    else if (number % 10 == 0 && Math.trunc(number / 100) == 0) {
      audioPlayList.push(this.audioService.getAudioSource() + `Numbers/` + number + `.mp3`);
    }
    // 1000X
    else if (number >= 1000) {
      if (number % 100 == 1) {
        audioPlayList.push(this.audioService.getAudioSource() + `Numbers/` + Math.trunc(number / 1000) * 1000 + `X.mp3`);
      }
      else {
        audioPlayList.push(this.audioService.getAudioSource() + `Numbers/` + Math.trunc(number / 1000) * 1000 + `X.mp3`);
        number %= 1000;
        audioPlayList = audioPlayList.concat(this.GetNumberSoundFiles(number));
      }
    }
    // 100X
    else if (number >= 100) {
      if (number % 100 == 1) {
        audioPlayList.push(this.audioService.getAudioSource() + `Numbers/` + Math.trunc(number / 100) * 100 + `X.mp3`);
      }
      else {
        audioPlayList.push(this.audioService.getAudioSource() + `Numbers/` + Math.trunc(number / 100) * 100 + `X.mp3`);
        number %= 100;
        audioPlayList = audioPlayList.concat(this.GetNumberSoundFiles(number));
      }
    }
    // 10X
    else if (number >= 20) {
      if (number % 10 == 1) {
        audioPlayList.push(this.audioService.getAudioSource() + `Numbers/` + Math.trunc(number / 10) * 10 + `X.mp3`);
      }
      else {
        audioPlayList.push(this.audioService.getAudioSource() + `Numbers/` + Math.trunc(number / 10) * 10 + `X.mp3`);
        number %= 10;
        audioPlayList = audioPlayList.concat(this.GetNumberSoundFiles(number));
      }
    }
    return audioPlayList;
  }
}