import { Injectable } from '@angular/core';
import { MobileResolutionService } from './mobile-resolution.service';
import { CookieService } from 'ngx-cookie-service';
import { SubscriptionType } from './account.service';

@Injectable({
  providedIn: 'root'
})
export class WebviewService {

  constructor(public mobileResolutionService: MobileResolutionService, private cookieService: CookieService) {
  }

  private batteryOptimizationSettingsRequired: boolean = false;

  async resolve() {
    this.InitGPSTWebviewFunctions();
  }

  public IsGPSTWebview(): boolean {
    return window.navigator.userAgent.includes("GPSTWebView");
  }

  private InitGPSTWebviewFunctions(): void {
    window['webViewObj'] = function Class() { };
    window['webViewObj']['goBack'] = () => {
      const p = this.mobileResolutionService.getMobileVisiblePanel();
      if (p == "slider" || p == "chargingstation" || p == "chargingstation" || p == "wind" || p == "search") {
        this.mobileResolutionService.setMobileVisiblePanel("");
        return true;
      }
      else if (document.getElementsByClassName("mat-dialog-container").length > 0) {
        if (document.getElementsByClassName("cdk-overlay-backdrop-showing").length > 0) {
          (document.getElementsByClassName('cdk-overlay-backdrop-showing')[document.getElementsByClassName("cdk-overlay-backdrop-showing").length - 1] as HTMLElement).click();
        }
        return true;
      }
      return false;
    };

    window['webViewObj']['setStartCoordsToYourGeolocation'] = function name() { };
    window['webViewObj']['startNavigation'] = function name() { };
    window['webViewObj']['stopNavigation'] = function name() { };

    window['webViewObj']['rhaPolygonUpdate'] = function name() { };
    window['webViewObj']['routeUpdate'] = function name() { };

    window['webViewObj']['openUrl'] = function name() { };
    window['webViewObj']['playAudio'] = function name() { };

    window['webViewObj']['facebookSignIn'] = function name() { };
    window['webViewObj']['facebookLogOut'] = function name() { };
    window['webViewObj']['facebookSignInCallBack'] = function name() { };

    window['webViewObj']['googleSignIn'] = function name() { };
    window['webViewObj']['googleLogOut'] = function name() { };
    window['webViewObj']['googleSignInCallBack'] = function name() { };

    window['webViewObj']['appleSignIn'] = function name() { };
    window['webViewObj']['appleLogOut'] = function name() { };
    window['webViewObj']['appleSignInCallBack'] = function name() { };
    
    window['webViewObj']['getAccessToken'] = () => {
      return this.getAccessToken();
    };
    window['webViewObj']['setAccessToken'] = function name() { };
    window['webViewObj']['clearAccessToken'] = function name() { };

    window['webViewObj']['setUserId'] = function name() { };

    window['webViewObj']['startSubscription'] = function name() { };
    window['webViewObj']['subscriptionSuccessCallback'] = function name() { };
    window['webViewObj']['openManageSubscription'] = function name() { };
    window['webViewObj']['onManageSheetClosed'] = function name() { };
    window['webViewObj']['subscriptionCancelCallback'] = function name() { };

    window['webViewObj']['smartConnectionSignIn'] = function name() { };
    window['webViewObj']['smartConnectionSignInCallback'] = function name() { };

    window['webViewObj']['openBatteryOptimizationWindow'] = function name() { };
    window['webViewObj']['getBatteryOptimizationActive'] = function name() {
      return false;
    }

    window['webViewObj']['OnSpeachRecognitionError'] = function name() { };
    window['webViewObj']['OnSpeachRecognitionError'] = function name() { };
    window['webViewObj']['startMicrophoneInteraction'] = function name() { };
    window['webViewObj']['microphoneInteractionCallback'] = function name() { };
  }

  public InitLocationFunction(locationFunction: (latitude: number, longitude: number, speed: number, accuracy: number, altitude: number, altitudeAccuracy: number, heading: number, timestamp: number) => void): void {
    window['webViewObj']['setWebViewLocation'] = locationFunction;
  }

  public InitGoToCurrentLocationFunction(goToCurrLocFunction: () => void): void {
    window['webViewObj']['goToCurrentLocation'] = goToCurrLocFunction;
  }

  public AddFacebookSignInCallback(callback: (isSucces: boolean, userId: string, accessToken: string) => void): void {
    window['webViewObj']['facebookSignInCallBack'] = callback;
  }

  public AddGoogleSignInCallback(callback: (isSucces: boolean, userId: string, accessToken: string) => void): void {
    window['webViewObj']['googleSignInCallBack'] = callback;
  }

  public AddAppleSignInCallback(callback: (isSucces: boolean, userId: string, accessToken: string) => void): void {
    window['webViewObj']['appleSignInCallBack'] = callback;
  }

  public AddSmartConnectionSignInCallback(callback: (isSucces: boolean, vehicleId: number) => void): void {
    window['webViewObj']['smartConnectionSignInCallback'] = callback;
  }

  public OpenUrl(url: string): void {
    window['webViewObj'].openUrl(url);
  }

  public RhaPolygonUpdate(rhaPolygon: string): void {
    window['webViewObj'].rhaPolygonUpdate(rhaPolygon);
  }

  public RouteUpdate(route: string): void {
    window['webViewObj'].routeUpdate(route);
  }

  public InitSetBattery(batteryFunction: (battery: number) => void): void {
    window['webViewObj']['setBattery'] = batteryFunction;
  }

  public StartNavigation(): void {
    window['webViewObj'].startNavigation();
  }

  public StopNavigation(): void {
    window['webViewObj'].stopNavigation();
  }

  public SetStartCoordsToYourGeolocation(): void {
    window['webViewObj'].setStartCoordsToYourGeolocation();
  }

  public PlayAudio(array: any[]): void {
    window['webViewObj'].playAudio(new Blob(array, { type: "arraybuffer" }));
  }

  public PlayAudioAndroid(audioFile: any): void {
    window['webViewObj'].playAudio(audioFile);
  }

  public FacebookSignIn(): void {
    window['webViewObj'].facebookSignIn();
  }

  public FacebookLogout(): void {
    window['webViewObj'].facebookLogOut();
  }

  public GoogleSignIn(): void {
    window['webViewObj'].googleSignIn();
  }

  public GoogleLogOut(): void {
    window['webViewObj'].googleLogOut();
  }

  public AppleSignIn(): void {
    window['webViewObj'].appleSignIn();
  }

  public AppleLogOut(): void {
    window['webViewObj'].appleLogOut();
  }

  public getAccessToken(): string {
    let evtoken = this.cookieService.get("evt");
    if (evtoken && evtoken != "null") {
      if (evtoken.slice(-6) == "social") {
        return evtoken.slice(0, -6);
      }
      else {
        return evtoken;
      }
    }
    else{
      return "";
    }
  }

  public SetAccessToken(accessToken:string, expires: number): void{
    window['webViewObj'].setAccessToken(accessToken, expires);
  }

  public ClearAccessToken(): void{
    window['webViewObj'].clearAccessToken();
  }

  public StartSubscription(subscriptionType:SubscriptionType): void{
    window['webViewObj'].startSubscription(subscriptionType);
  }

  public addSubscriptionSuccessCallback(callback: (isSuccess: boolean, subscriptionType: SubscriptionType) => void): void {
    window['webViewObj']['subscriptionSuccessCallback'] = callback;
  }

  public addSubscriptionCancelCallback(callback: () => void): void {
    window['webViewObj']['subscriptionCancelCallback'] = callback;
  }

  public OpenManageSubscription(): void{
    window['webViewObj'].openManageSubscription();
  }

  public addOnManageSheetClosed(callback: (isSuccess: boolean) => void): void {
    window['webViewObj']['onManageSheetClosed'] = callback;
  }

  public SetUserId(userId:string): void{
    window['webViewObj'].setUserId(userId);
  }

  public SmartConnectionSignIn(UserCarId: number): void {
    window['webViewObj'].smartConnectionSignIn(UserCarId);
  }

  public OpenBatteryOptimizationWindow(): void {
    window['webViewObj'].openBatteryOptimizationWindow();
  }

  public setBatteryOptimizationSettingsRequired(batteryOptimizationSettingsRequired: boolean): void {
    this.batteryOptimizationSettingsRequired = batteryOptimizationSettingsRequired;
  }

  public getBatteryOptimizationSettingsRequired(): boolean {
    return this.batteryOptimizationSettingsRequired;
  }

  public getBatteryOptimizationActive(): boolean {
    return window['webViewObj'].getBatteryOptimizationActive();
  }

  public startMicrophoneInteraction(): void{
    window["webViewObj"].startMicrophoneInteraction();
  }

  public addMicrophoneInteractionCallback(callback: (permission: boolean) => void): void {
    window["webViewObj"]['microphoneInteractionCallback'] = callback;
  }

  public onSpeachRecognitionError(): void{
    window["webViewObj"].onSpeachRecognitionError();
  }

  public addOnSpeachRecognitionErrorCallback(callback: () => void): void {
    window['webViewObj']['OnSpeachRecognitionErrorCallback'] = callback;
  }
}
