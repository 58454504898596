<div class="subscription-dialog">

    <h1 mat-dialog-title>
        Cancel subscription
    </h1>
    <div mat-dialog-content>
        <div class="logo">
            <img src="assets/icons/dark/logo/ev_navigation_logo.png" />
            <h2 *ngIf="!type?.includes('proplus.')">PRO</h2>
            <img *ngIf="type?.includes('proplus.')" id="proplus-icon" src="assets/icons/default/ui/pro_logo.svg" />
        </div>

        <div class="content">
            <p>Would you like to delete the PRO
                <small *ngIf="type?.includes('proplus.')">+</small>
                subscription?
            </p>

            <p class="grey">
                We're sorry to see you leave. Please note you still have 10 days left on your subscription. After this period, you
                will lose access to all features if you cancel your subscription.
            </p>
        </div>

    </div>
    <div mat-dialog-actions>
        <button mat-button (click)="delete()">{{
            languageService.languageJSON.Global_Delete }}</button>
        <button mat-button (click)="close()">{{
            languageService.languageJSON.Global_Cancel }}</button>
    </div>

</div>